import React from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import reportService from '../../../services/reportService';
import { eventSlugnameTrim } from '../../../components/helpers';
import Swal from 'sweetalert2';
import { renderMonthElement } from '../../../components/form/Form';

const ServicesReport = (props) => {
  const { selectedEventSlugname, handleDate, set_handleDate, setInputs, inputs } = props;
  const [open, setOpen] = React.useState(false);
  const [focused, set_focused] = React.useState(null);
  const [loading, set_loading] = React.useState(false);

  function getAttendantReport() {
    set_loading(true);

    const { startDate, endDate, volunteer, attended, paid } = inputs;

    let filters = {
      startDate: startDate || handleDate?.startDate?.toDate(),
      endDate: endDate || handleDate?.endDate?.toDate(),
      eventSlugname: selectedEventSlugname,
      volunteer,
      attended,
      paid,
    };

    let body = { ...filters };
    let URL = `/event/serviceReport`;
    let fileName = `InformeAsistencia${selectedEventSlugname}.xlsx`;

    reportService
      .getReport({ URL, fileName, body })
      .then((response) => {
        set_loading(false);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  const handleDateChange = (startDate, endDate) => {
    setInputs({
      ...inputs,
      startDate: startDate ? startDate.startOf('day')._d.getTime() : null,
      endDate: endDate ? endDate.endOf('day')._d.getTime() : null,
    });
    set_handleDate({ startDate, endDate });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Descargar reporte</Button>}
    >
      <Modal.Header>Descargar reporte de servicios</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h2>Filtros</h2>
          <p>
            <small>Si no seleccionas un rango se descargará el mes actual</small>
          </p>
          <div>
            <strong>Evento:</strong> {eventSlugnameTrim(selectedEventSlugname)}
          </div>
          <div
            style={{
              display: 'inline-block',
              margin: '7px',
            }}
          >
            <label htmlFor={'date'}>Fechas</label>{' '}
            <DateRangePicker
              startDatePlaceholderText="Fecha Inicio"
              endDatePlaceholderText="Fecha Fin"
              startDate={handleDate.startDate}
              startDateId="startDateRange"
              minimumNights={0}
              endDate={handleDate.endDate}
              endDateId="endDateRange"
              onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
              focusedInput={focused}
              onFocusChange={(focused) => set_focused(focused)}
              isOutsideRange={() => false}
              renderMonthElement={renderMonthElement}
            />
            {new Date(inputs.date) === 'Invalid Date' || !inputs.date
              ? null
              : moment(inputs.date).format('DD-MMMM-YYYY')}
            {}
          </div>
          <div style={{ width: '100%' }}>
            <Checkbox
              label="Filtrar por voluntarios"
              name="volunteer"
              checked={inputs.volunteer}
              toggle
              onClick={() => setInputs({ ...inputs, volunteer: !inputs.volunteer })}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Checkbox
              label="Filtrar por asistencia"
              name="attended"
              checked={inputs.attended}
              toggle
              onClick={() => setInputs({ ...inputs, attended: !inputs.attended })}
            />
          </div>
          {selectedEventSlugname?.includes('.') && (
            <div style={{ width: '100%' }}>
              <Checkbox
                label="Filtrar por pagos"
                name="paid"
                checked={inputs.paid}
                toggle
                onClick={() => setInputs({ ...inputs, paid: !inputs.paid })}
              />
            </div>
          )}
          <p style={{ margin: '20px 0 0 0' }}>
            <small>
              En la última columna, donde está la información adicional, debes convertir los valores
              a columnas para visualizarlo correctamente usando "-"
            </small>
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => {
            setOpen(false);
            setInputs({});
          }}
        >
          Cerrar
        </Button>
        <Button
          style={{ margin: '5px' }}
          secondary
          disabled={loading}
          loading={loading}
          onClick={() => getAttendantReport()}
        >
          Descargar reporte
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ServicesReport;
