import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { useState } from 'react';
import { customComparator } from '../../table/helpers';

const COLORS = ['#242323', '#fc9133', '#8dfce6', '#ff6b6b', '#9b59b6', '#3498db'];

const PieChartComponent = ({
  title,
  subtitle,
  data,
  width = 400,
  height = 400,
  outerRadius = 150,
  dataKey = 'value',
  nameKey = null,
  customTooltip = null,
}) => {
  const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Función que maneja el clic en el gráfico
  const handlePieClick = (data) => {
    const extractedData = data.data.map((item) => ({
      name: item.name,
      lastName: item.lastName,
      phone: item.phone,
      identification: item.identification,
    }));
    setList(extractedData);
    setIsModalOpen(true); // Abrir el modal al hacer clic en el gráfico
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!data || data.length === 0) return null;

  return (
    <div className="graphic">
      {title && <h3>{title}</h3>}
      {subtitle && <p>{subtitle}</p>}
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          dataKey={dataKey}
          nameKey={nameKey}
          cx="50%"
          cy="50%"
          outerRadius={outerRadius}
          fill="#8884d8"
          label={({ value, percentage }) => `${value} (${percentage}%)`}
          onClick={(entry, index) => handlePieClick(entry, index)} // Evento de clic
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={customTooltip || null} />
        <Legend />
      </PieChart>

      {/* Modal que se muestra con los detalles al hacer clic */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Detalles de Líderes</h2>
            <button className="close-btn" onClick={closeModal}>
              Cerrar
            </button>
            <div>
              {list.length > 0 ? (
                <ul>
                  {list
                    .sort((a, b) => customComparator(a?.name, b?.name))
                    .map((item, index) => (
                      <li key={index}>
                        <a
                          href={`https://info.comunidadmde.com/perfil/crecer?identification=${item.identification}&phone=${item.phone}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ cursor: 'pointer' }}
                        >
                          {item.name} {item.lastName}
                        </a>
                      </li>
                    ))}
                </ul>
              ) : (
                <p>No hay detalles para mostrar.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChartComponent;
