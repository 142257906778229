import React from 'react';
import Button from '../../components/common/Button';
import { Select, Input } from 'semantic-ui-react';
import NewMembersRow from '../../components/common/adminTable/NewMemberRow';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import membersForms from '../../services/membersForms';
import NewMemberModal from '../church-members/newMember/newMemberTutorForm';
import ReportButton from './ReportButton';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { changeArrayLine } from '../../components/helpers';
import { renderMonthElement } from '../../components/form/Form';

const filters = [
  { key: 'Si', value: true, text: 'Si' },
  { key: 'No', value: false, text: 'No' },
];

const NewMembersReport = (props) => {
  const [allNewMembers, set_allNewMembers] = React.useState([]);
  const [loaded, set_loaded] = React.useState(null);
  const [showNewMembers, set_showNewMembers] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);
  const [handleDate, set_handleDate] = React.useState({});
  const [focused, set_focused] = React.useState({});

  React.useEffect(() => {
    getInfo();
  }, []);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  const setFilters = () => {
    let filter = {};
    Object.entries(inputs)
      .filter((input) => input[1] != null)
      .forEach((element) => {
        filter[element[0]] = element[1];
      });
    return filter;
  };

  function getInfo(page = false) {
    set_loaded(false);
    set_endPage(false);

    let body = { filter: setFilters(), currentPage };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    membersForms
      .newMembersReport(body)
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);
          if (page && body.currentPage > 1)
            set_allNewMembers([...allNewMembers, ...response.data.newMember]);
          else set_allNewMembers(response.data.newMember);
          if (response.data.newMember.length === 0) set_endPage(true);
        } else {
          set_loaded(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  const handleDateChange = (startDate, endDate) => {
    setInputs({
      ...inputs,
      rangeDate: {
        startDate: startDate ? startDate._d.getTime() : null,
        endDate: endDate ? endDate._d.getTime() : null,
      },
    });
    set_handleDate({ startDate, endDate });
  };

  const inputStyles = {
    display: 'inline-block',
    width: '45%',
    margin: ' 7px 0',
  };

  const changeNewMembersLine = (line) =>
    changeArrayLine(line, allNewMembers, set_allNewMembers, 'guest_id');

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button text={'Atrás'} onClick={() => props.history.push('/admin?folder=experience')} />
        <Button text={'Buscar'} onClick={getInfo} />
        <div>
          <Input
            loading={!loaded}
            name={'keyword'}
            onChange={handleInputs}
            value={inputs.keyword}
            className={'crecerp1c3'}
            style={inputStyles}
            onKeyPress={onEnter}
            placeholder="Buscar..."
          />
          <Select
            placeholder="Filtrar por contactado"
            style={inputStyles}
            onChange={selectHandle}
            loading={!loaded}
            name="contacted"
            options={[{ key: null, value: null, text: 'Todos' }, ...filters]}
          />
          <Select
            placeholder="Filtrar por seguimiento"
            style={inputStyles}
            onChange={selectHandle}
            loading={!loaded}
            name="followUps"
            options={[{ key: null, value: null, text: 'Todos' }, ...filters]}
          />
          <DateRangePicker
            startDatePlaceholderText="Fecha Inicio"
            endDatePlaceholderText="Fecha Fin"
            startDate={handleDate.startDate}
            startDateId="startDateRange"
            minimumNights={0}
            onClose={() => getInfo()}
            endDate={handleDate.endDate}
            endDateId="endDateRange"
            onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
            showClearDates
            focusedInput={focused}
            onFocusChange={(focused) => set_focused(focused)}
            isOutsideRange={() => false}
            renderMonthElement={renderMonthElement}
          />

          <ReportButton
            URL={`/members/members-report`}
            fileName={`reporte_nuevos_miembros.xlsx`}
            event="Reporte Conexión"
            filters={{ filter: setFilters() }}
          />
        </div>
      </div>
      <div>
        <p>Total en pantalla: {allNewMembers.length}</p>
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Celular</th>
              <th>Contactado</th>
              <th>Seguimientos</th>
            </tr>
          </thead>
          <tbody>
            {allNewMembers.map((newMember, i) => (
              <>
                {showNewMembers === newMember.id && (
                  <NewMemberModal
                    set_showNewMembers={set_showNewMembers}
                    showNewMembers={showNewMembers}
                    changeNewMembersLine={changeNewMembersLine}
                    newMember={newMember}
                  />
                )}
                <NewMembersRow newMember={newMember} set_showNewMembers={set_showNewMembers} />
              </>
            ))}
          </tbody>
        </table>
        <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
          <Button
            disabled={endPage || !loaded}
            onClick={() => loadMore()}
            text={loaded ? 'Cargar más' : 'Cargando...'}
          />
        </div>
      </div>
    </section>
  );
};

export default NewMembersReport;
