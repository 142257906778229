import { useContext, useState } from 'react';
import { DashboardProfileComponent } from './styles/DashboardProfileComponent';
import { GuestDashboardContext } from './guestDashboardContext';
import UploadProfilePhoto from '../../helpers/guests/upload_photo';
import guestService from '../../../services/guest';
import Swal from 'sweetalert2';

const Profile = () => {
  const [image, set_image] = useState(null);
  const { guest, setGuest } = useContext(GuestDashboardContext);
  const [loading, set_loading] = useState(false);

  const uploadProfilePicInput = () => {
    const data = new FormData();
    data.append('profile', image ? image : null);
    data.append('identification', guest.identification);

    guestService
      .updateGuest(guest.id, data)
      .then((response) => {
        if (response.data.success) {
          Swal.fire(response.data.message);
          setGuest({ ...guest, photo: response?.data?.updateFromUser?.photo });
        }
        set_image(null);
        set_loading(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  };

  return (
    <DashboardProfileComponent>
      <p className="greeting">Hola, {guest.name}!</p>
      <h2 style={{ fontSize: '35px' }}>
        Estos son tus <span className="profile-text">Datos</span>
      </h2>
      <br />
      <div className="columnsProfile">
        <div className="columnProfile">
          <div className="image-wrapper">
            <UploadProfilePhoto
              name={'image'}
              labelDescription={'foto'}
              errorInputs={{}}
              imageFile={null}
              filling
              inputs={guest}
              set_image={set_image}
            />
            {image && !loading ? (
              <button
                onClick={() => {
                  set_loading(true);
                  uploadProfilePicInput();
                }}
                className={`ui compact upload-photo button ${loading ? 'loading' : ''}`}
              >
                Guardar
              </button>
            ) : null}
          </div>
          <div className="profile-field">
            <label>Nombres</label>
            <div className="field-value name">{guest.name}</div>
          </div>
          <div className="profile-field">
            <label>Apellidos</label>
            <div className="field-value">{guest.lastName}</div>
          </div>
          <div className="profile-field">
            <label>ID</label>
            <div className="field-value">{guest.identification}</div>
          </div>
        </div>

        <div className="columnProfile">
          <br></br>
          <div className="profile-field">
            <label>Correo Electrónico</label>
            <div className="field-value">{guest.email}</div>
          </div>
          <div className="profile-field">
            <label>Teléfono</label>
            <div className="field-value">{guest.phone}</div>
          </div>
          <div className="profile-field">
            <label>Cumpleaños</label>
            <div className="field-value">{guest.birthdate}</div>
          </div>
          <div className="profile-field">
            <label>Líder</label>
            <div className="field-value">{guest?.GuestLeader?.leaderFullName}</div>
          </div>
          <div className="profile-field">
            <label>Teléfono del Líder</label>
            <div className="field-value">{guest?.GuestLeader?.leaderPhone}</div>
          </div>
        </div>

        <div className="columnProfile"></div>
      </div>
    </DashboardProfileComponent>
  );
};

export default Profile;
