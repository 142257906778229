import React from 'react';
import { Button, Select, Tab } from 'semantic-ui-react';
import EventService from '../../../services/eventService';
import CreateService from './service-creation/createService';
import ChurchServiceGuests from './churchServiceGuests';
import Swal from 'sweetalert2';
import ChurchServiceDetails from './service-creation/serviceDetails';
import ServicesReport from './servicesReport';
import { formSelectService, sortObjects, chPerm } from '../../../components/helpers';
import { DateRangePicker } from 'react-dates';
import GenerateLinksModal from './generateLinksModal';
import moment from '../../../services/moment_instance';
import authService from '../../../services/auth';
import { renderMonthElement } from '../../../components/form/Form';
import Table from '../../../components/table/aggrid';
import { ServiceListRowDef } from '../../../components/common/adminTable/ServiceListRow';

const ViewAllServices = (props) => {
  const [loading, set_loading] = React.useState(false);
  const [activeTab, set_activeTab] = React.useState(0);
  const [services, set_services] = React.useState({});
  const [serviceList, set_serviceList] = React.useState([]);
  const [inputs, setInputs] = React.useState({});
  const [serviceAttendance, set_serviceAttendance] = React.useState([]);
  const [previousEventsNames, set_previousEventsNames] = React.useState([]);
  const [clickedEvent, set_clickedEvent] = React.useState({});
  const [handleDate, set_handleDate] = React.useState({
    startDate: moment().startOf('month').startOf('day'),
    endDate: moment().endOf('month').endOf('day'),
  });
  const [focused, set_focused] = React.useState({});

  const getDate = (date) => moment.tz(date, 'America/Bogota').format('YYYY-MM-DD');

  React.useEffect(() => {
    getPreviousEventNames(60, 365);
  }, []);

  React.useEffect(() => {
    getEvents();
  }, [inputs.startDate, inputs.endDate, inputs.eventSlugname]);

  const selectHandle = (a, { value, name }) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getEvents = () => {
    const { eventSlugname, startDate, endDate } = inputs;
    set_loading(true);

    let body = {
      startDate: startDate || handleDate?.startDate?.toDate(),
      endDate: endDate || handleDate?.endDate?.toDate(),
    };
    EventService.getAuditoriumEvents(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          let events = {};
          let eventList = [];
          sortObjects(response.data.events, 'date', 'asc').forEach((event) => {
            if (eventSlugname === event.eventSlugname) {
              events[getDate(event.date)] = [...(events[getDate(event.date)] || []), event];
            }
            if (event.eventSlugname !== 'Auditorio_Principal') eventList.push(event);
          });
          set_services(events);
          set_serviceList(eventList);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const getEvent = (id, eventSlugname) => {
    set_loading(true);
    EventService.eventAttendance({ id, eventSlugname })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          if (response.data.event.length) {
            set_serviceAttendance(response.data.event);
          } else {
            Swal.fire('No hay usuarios registrados a este servicio');
          }
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const getPreviousEventNames = (numberPrevDays = 9999, numberPostDays = 9999) => {
    set_loading(true);
    const body = {
      prevDays: numberPrevDays,
      postDays: numberPostDays,
    };
    EventService.getEventsNames(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
          if (!inputs.eventSlugname) {
            setInputs((prev) => ({
              ...prev,
              eventSlugname: (chPerm(['Auditorio_PrincipalAdmin'])
                ? response.data.eventsNames
                : showAuthServices())[0],
            }));
          }
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const handleDateChange = (startDate, endDate) => {
    setInputs((prev) => ({
      ...prev,
      startDate: startDate ? startDate.startOf('day')._d.getTime() : null,
      endDate: endDate ? endDate.endOf('day')._d.getTime() : null,
    }));
    set_handleDate({ startDate, endDate });
  };

  const showAuthServices = () => {
    if (chPerm(['Auditorio_PrincipalAdmin'])) {
      return previousEventsNames;
    }
    return authService?.getCurrentUser()?.allowed_events || [];
  };

  const panes = [
    {
      menuItem: 'Por nombre',
      render: () => (
        <Tab.Pane>
          <div>
            {formSelectService({
              Select,
              label: 'Selecciona un evento',
              name: 'eventSlugname',
              handler: selectHandle,
              value: inputs.eventSlugname,
              options: showAuthServices(),
            })}
            {!inputs.allEventNames && chPerm(['Auditorio_Principal']) && (
              <Button
                secundary
                size="tiny"
                onClick={() => {
                  getPreviousEventNames();
                  setInputs((prev) => ({ ...prev, allEventNames: true }));
                }}
              >
                Listar todos los nombres
              </Button>
            )}
          </div>
          <label
            style={{
              display: 'block',
              padding: '20px 0px 20px 0px',
              fontSize: '25px',
            }}
          >
            Selecciona el servicio que quieres visualizar
          </label>
          {!loading && Object.keys(services).length === 0 && (
            <p>No hay servicios creados en las fechas seleccionadas.</p>
          )}
          {loading && <p>Cargando servicio...</p>}
          <div className="dates">
            {Object.keys(services).map((date) => (
              <>
                <ChurchServiceDetails
                  previousEventsNames={previousEventsNames}
                  getEvents={getEvents}
                  date={date}
                  services={services[date]}
                />
                {services[date].map((service) => (
                  <Button
                    primary
                    disabled={loading}
                    onClick={(e) => {
                      getEvent(service.id, service.eventSlugname);
                      set_clickedEvent(service);
                    }}
                  >
                    <div className="time">
                      {moment.tz(service.date, 'America/Bogota').format('hh:mm a')}
                    </div>
                    {Boolean(service.capacity) && Boolean(service.AuditoriumGuests) && (
                      <div className="center">Asistentes {service.AuditoriumGuests?.length}</div>
                    )}
                  </Button>
                ))}
              </>
            ))}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Por lista de eventos',
      render: () => (
        <Tab.Pane>
          <Table
            rowData={serviceList}
            rowHeight={60}
            columnDefs={ServiceListRowDef(getEvent, set_clickedEvent)}
            defaultCol={{
              suppressMovable: true,
            }}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="service selectAService">
      <h1
        style={{
          display: 'block',
          padding: '20px',
          textAlign: 'center',
          fontSize: '45px',
        }}
      >
        Servicios y Eventos
      </h1>
      {serviceAttendance.length === 0 ? (
        <>
          <>
            {chPerm(['Auditorio_PrincipalAdmin']) && (
              <CreateService
                previousEventsNames={previousEventsNames}
                inputs={inputs}
                getPreviousEventNames={getPreviousEventNames}
                setInputs={setInputs}
                getEvents={getEvents}
              />
            )}
            {chPerm(['Auditorio_Principal']) && (
              <ServicesReport
                inputs={inputs}
                setInputs={setInputs}
                handleDate={handleDate}
                set_handleDate={set_handleDate}
                selectedEventSlugname={inputs.eventSlugname}
              />
            )}
            <GenerateLinksModal previousEventsNames={previousEventsNames} />
          </>

          <div style={{ padding: '20px 0' }}>
            <DateRangePicker
              startDatePlaceholderText="Fecha Inicio"
              endDatePlaceholderText="Fecha Fin"
              startDate={handleDate.startDate}
              startDateId="startDateRange"
              minimumNights={0}
              endDate={handleDate.endDate}
              endDateId="endDateRange"
              onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
              focusedInput={focused}
              onFocusChange={(focused) => set_focused(focused)}
              isOutsideRange={() => false}
              renderMonthElement={renderMonthElement}
            />
          </div>
          <Tab
            panes={panes}
            onTabChange={(e, { activeIndex }) => set_activeTab(activeIndex)}
            activeIndex={activeTab}
          />
        </>
      ) : (
        <ChurchServiceGuests
          set_serviceAttendance={set_serviceAttendance}
          serviceAttendance={serviceAttendance}
          clickedEvent={clickedEvent}
          history={props.history}
          getEvents={getEvents}
          getEvent={getEvent}
        />
      )}
    </div>
  );
};

export default ViewAllServices;
