import proxybase from './proxybase';

class AdminService {
  getNewMembersProgress({ startDate, endDate }) {
    const body = { startDate, endDate };
    return proxybase
      .post('/dashboard/newMembersProgress', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getNewMembersInGroups({ startDate, endDate }) {
    const body = { startDate, endDate };
    return proxybase
      .post('/dashboard/newMembersInGroups', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  crecerProgress({ startDate, endDate, step }) {
    const body = { startDate, endDate, step };
    return proxybase
      .post('/dashboard/crecerProgress', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAllCrecerAttendance({ step, startDate, endDate, classesAttended, classesAttendedInRange }) {
    const body = { step, startDate, endDate, classesAttended, classesAttendedInRange };
    return proxybase
      .post('/crecer/getAllCrecerAttendance', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getCrecerVsVolunteerProgress({ step, startDate, endDate, classesAttended }) {
    const body = { step, startDate, endDate, classesAttended };
    return proxybase
      .post('/dashboard/crecerVsVolunteerProgress', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAllVolunteersByGuests({ keyword, volunteerRole, volunteerSection }) {
    const body = { keyword, volunteerRole, volunteerSection };
    return proxybase
      .post('/dashboard/AllVolunteersByGuests', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getVolunteersLeading({ keyword, volunteerRole, volunteerSection }) {
    const body = { keyword, volunteerRole, volunteerSection };
    return proxybase
      .post('/dashboard/volunteersLeading', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAllCrecerAttendanceReports({ step, startDate, endDate, classesAttended }) {
    const body = { step, startDate, endDate, classesAttended };
    return proxybase
      .post('/crecer/getAllCrecerAttendanceReports', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAllCrecerSteps({ startDate, endDate, classesAttended, classesAttendedInRange }) {
    const body = { startDate, endDate, classesAttended, classesAttendedInRange };
    return proxybase
      .post('/dashboard/getAllCrecerSteps', body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const membersProgressService = new AdminService();
export default membersProgressService;
