import './styles/certificateGenerator.css';

const Graphic = ({ color }) => {
  return (
    <div id="graphic" className="graphic certifications">
      <svg
        width="100%"
        height="100%"
        viewBox="1600 645 1000 10"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M2046.7 826.055 2065.78 826.055C1970.98 654.996 1808.35 532.543 1621 486.503L1621 503.591C1799.27 548.577 1954.29 664.434 2046.7 826.01M2591 1035 2591 1011.54 2341.55 763.34 1923.74 346 1900.21 346 2329.81 775.113ZM2591 459.887 2476.98 346 2453.5 346 2591 483.342ZM2591 735.853 2591 712.352 2224.23 346 2200.71 346Z"
            fill={color}
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </div>
  );
};

export default Graphic;
