import React from 'react';
import CoupleGroupRegistration from './registration/groupRegistration';
import LeaderSelect from './registration/leaderSelect';
import GroupSummary from './registration/groupSummary';
import MemberLogGroup from './registration/memberLogGroup';

export const groupTitle = (params) => {
  if (window.location.pathname.includes('crecer')) {
    return (
      <h1 style={{ margin: '5px 0 15px 0' }}>
        ¡Bienvenido al seminario de {params?.seminary.replace(/_/g, ' ')}!
      </h1>
    );
  }
  return <h1 style={{ margin: '5px 0 15px 0' }}>¡Bienvenido a tu grupo en Comunidad!</h1>;
};

const GroupReservation = (props) => {
  const [Step, setStep] = React.useState('check');
  const [inputs, setInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);
  const params = props.match.params;

  const stepRender = () => {
    if (Step === 'check') {
      return (
        <MemberLogGroup
          setInputs={setInputs}
          parsedQueryString={{}}
          inputs={inputs}
          next="person1"
          setStep={setStep}
          loading={loading}
          set_loading={set_loading}
        />
      );
    } else if (Step === 'person1') {
      return (
        <CoupleGroupRegistration
          setInputs={setInputs}
          inputs={inputs}
          person="person1"
          next="person2"
          Step={Step}
          setStep={setStep}
        />
      );
    } else if (Step === 'person2') {
      return (
        <CoupleGroupRegistration
          setInputs={setInputs}
          inputs={inputs}
          person="person2"
          next="selectLeaders"
          Step={Step}
          setStep={setStep}
        />
      );
    } else if (Step === 'selectLeaders') {
      return (
        <LeaderSelect setInputs={setInputs} inputs={inputs} next="summary" setStep={setStep} />
      );
    } else if (Step === 'summary') {
      return (
        <GroupSummary
          match={props.match}
          setInputs={setInputs}
          inputs={inputs}
          next="summary"
          setStep={setStep}
        />
      );
    }
  };

  return (
    <div className="group_registration">
      {groupTitle(params)}
      {stepRender()}
    </div>
  );
};

export default GroupReservation;
