import moment from 'moment';
import React from 'react';
import { Button } from 'semantic-ui-react';
import UploadProfilePhoto from '../../../../../components/helpers/guests/upload_photo';
import NewUpdateChildren from './StepParentMatch/new-updateChildren';
import StepParentMatchChildrenTable from './StepParentMatch/stepParentMatch-Table';
import guestService from '../../../../../services/guest';
import { chPerm } from '../../../../../components/helpers';
import Form from '../../../../../components/form/Form';

const StepParentMatchChildren = (props) => {
  const { parent, children, getInfo, set_step, setInputs } = props;
  const [image, set_image] = React.useState(null);
  const [Attendant, set_Attendant] = React.useState(parent);
  const [editing, set_editing] = React.useState(false);

  const editInfo = () => {
    const { name, lastName, phone, identification } = Attendant;
    const data = new FormData();
    if (image) data.append('profile', image);
    if (name) data.append('name', name);
    if (lastName) data.append('lastName', lastName);
    if (phone) data.append('phone', phone);
    if (identification) data.append('identification', identification);

    guestService
      .updateGuest(props.parent?.id, data)
      .then(() => {
        set_editing(false);
        set_image(null);
      })
      .catch(() => set_image(null));
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_Attendant({ ...Attendant, [name]: value });
  };

  const handleDropDown = ({ name, value }) => {
    set_Attendant({ ...Attendant, [name]: value });
  };

  const GoBackButton = () => (
    <Button
      size="mini"
      secondary
      onClick={() => {
        set_step(1);
        setInputs({});
      }}
    >
      Volver al inicio
    </Button>
  );

  return (
    <div className="parentMatch">
      <div className="back">
        <GoBackButton />
      </div>
      <h2>Acudiente</h2>
      <div className="acudiente">
        <div className="photo-acudiente">
          <UploadProfilePhoto
            name={'parentImage'}
            labelDescription={'foto'}
            filling={true}
            imageFile={image}
            inputs={{ photo: parent.photo }}
            set_image={set_image}
          />
          {image && (
            <Button onClick={() => editInfo()} size="tiny">
              Enviar foto
            </Button>
          )}
        </div>
        <div className="info-acudiente">
          <h3>
            {Attendant.name} {Attendant.lastName}
          </h3>
          <p className="info">
            Cedula <strong>{Attendant.identification}</strong>
          </p>
          <p className="info">
            Edad <strong>{moment().diff(Attendant.birthdate, 'years')}</strong>
          </p>
          <p className="info">
            Telefono <strong>{Attendant.phone}</strong>
          </p>
          {chPerm(['generaciones', 'eventAdmin']) && (
            <Button size="mini" onClick={() => set_editing((prev) => !prev)}>
              {!editing ? 'Editar acudiente' : 'Cancelar edición'}
            </Button>
          )}
        </div>
        <div className="form-acudiente">
          {!!editing && (
            <Form
              saveTag={'Enviar cambios'}
              Attendant={Attendant}
              userprofile={true}
              handleInputs={handleInputs}
              handleDropDown={handleDropDown}
              errorInputs={{}}
              sendInForm
              showBirthdate
              showIdentification={false}
              showEmail={false}
              submit={editInfo}
            />
          )}
        </div>
      </div>
      <div className="children">
        <h2>Niños a su cargo</h2>
        <NewUpdateChildren parent={parent} getInfo={getInfo} />
        <p style={{ fontSize: '18px', margin: '15px 0', fontStyle: 'italic' }}>
          Al ingresar a su hijo al sistema, así como usar los servicios de generaciones, <br />
          estás aceptando los terminos y condiciones de Iglesia Comunidad Cristiana de Fé Viento
          Fresco.
        </p>
        {!!children.length && (
          <StepParentMatchChildrenTable children={children} parent={parent} getInfo={getInfo} />
        )}
      </div>
      <div className="back bottom">
        <GoBackButton />
      </div>
    </div>
  );
};

export default StepParentMatchChildren;
