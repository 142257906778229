import styled from '@emotion/styled';
import moment from 'moment';
import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { customComparator } from '../../../components/table/helpers';

const BirthdayTable = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 15px 0;
  }
  th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: 400;
    padding: 10px;
  }
  td {
    padding: 10px;
    border-bottom: 1px solid #f2f2f2;
  }
  tr:hover {
    background-color: #f9f9f9;
  }
`;

const getDataInRange = (volunteers, showAllBirthdates, daysRange = 15) => {
  const today = moment().tz('America/Bogota').startOf('day');
  const startDate = today.clone().subtract(daysRange, 'days');
  const endDate = today.clone().add(daysRange, 'days');

  const isDateInRange = (date) => {
    const birthMoment = moment(date).tz('America/Bogota');
    const birthMonthDay = birthMoment.format('MM-DD');

    const isBetween = (momentToCheck) => {
      return moment(momentToCheck).isBetween(startDate, endDate, null, '[]');
    };

    const dateInThisYear = moment(`${today.year()}-${birthMonthDay}`, 'YYYY-MM-DD');
    const dateInNextYear = moment(`${today.year() + 1}-${birthMonthDay}`, 'YYYY-MM-DD');
    const dateInLastYear = moment(`${today.year() - 1}-${birthMonthDay}`, 'YYYY-MM-DD');

    return isBetween(dateInThisYear) || isBetween(dateInNextYear) || isBetween(dateInLastYear);
  };

  let volunteersInRange = volunteers?.filter(
    (volunteer) => isDateInRange(volunteer?.Guest?.birthdate) || showAllBirthdates
  );

  volunteersInRange.sort((a, b) => {
    const dateA = moment(a?.Guest?.birthdate).format('MM-DD');
    const dateB = moment(b?.Guest?.birthdate).format('MM-DD');
    return customComparator(dateA, dateB);
  });

  return volunteersInRange;
};

const VolunteersBirthdays = (props) => {
  const { volunteers, showAllBirthdates, showArea, daysRange } = props;
  const volunteersInRange = getDataInRange(volunteers, showAllBirthdates, daysRange);
  return (
    <BirthdayTable>
      {volunteersInRange.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Fecha</th>
              {!!showArea && <th>Area de servicio</th>}
            </tr>
          </thead>
          <tbody>
            {volunteersInRange.map((volunteer, index) => (
              <tr key={index}>
                <td>
                  {volunteer?.Guest.name} {volunteer?.Guest.lastName}
                </td>
                <td>{moment(volunteer?.Guest?.birthdate).format('DD [de] MMMM')}</td>
                {!!showArea && <td>{volunteer?.serviceArea}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No se encontraron voluntarios con cumpleaños{showAllBirthdates ? '' : ' cercanos'}.</p>
      )}
    </BirthdayTable>
  );
};

export const VolunteersBirthdaysModal = (props) => {
  const { volunteers, showArea, daysRange, onOpen } = props;
  const [open, setOpen] = useState(false);
  const [showAllBirthdates, setShowAllBirthdates] = useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
        onOpen();
      }}
      open={open}
      trigger={<Button>Ver Cumpleaños 🎂</Button>}
    >
      <Modal.Header>Ver Cumpleaños de todo el equipo 🎂</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Button
            style={{ margin: '0 0 15px 0' }}
            onClick={() => setShowAllBirthdates(!showAllBirthdates)}
          >
            Mostrar{' '}
            {showAllBirthdates ? 'solo los próximos cumpleaños' : 'los cumpleaños de todo el año'}
          </Button>
          <VolunteersBirthdays
            showAllBirthdates={showAllBirthdates}
            volunteers={volunteers}
            showArea={showArea}
            daysRange={daysRange}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default VolunteersBirthdays;
