import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import Form from '../../../components/form/Form';
import { eventSlugnameTrim, formSelectService, groupCategories } from '../../../components/helpers';
import { Button, Select } from 'semantic-ui-react';
import membersForms from '../../../services/membersForms';
import Swal from 'sweetalert2';
import { habeasData } from '../../helpers/habeasData';

export const seminaryInfo = (activityName, summary = false) => {
  return (
    <div style={{ margin: '10px 0' }}>
      <p>
        <strong>Inicio:</strong> en la semana del 17 de febrero de acuerdo al día del grupo
        seleccionado.
      </p>
      {activityName.includes('Para_Siempre') && (
        <p>
          {!summary && (
            <p>
              Pueden elegir el grupo de acuerdo a sus agendas (Día, Hora). Selecciona el grupo al
              cuál desean asistir.
            </p>
          )}
          La pareja facilitadora del seminario los estará contactando para informarles del día de
          inicio, pero si tienes alguna inquietud puedes comunicarte al
          <a
            href="https://api.whatsapp.com/send?phone=573011147734"
            target="_blank"
            rel="noreferrer"
          >
            Whatsapp 3011147734
          </a>
          .
        </p>
      )}
      {activityName.includes('Transformacion_Empresarial') && (
        <p>
          {!summary && (
            <p>
              Puedes elegir el grupo de acuerdo a tu necesidad de agenda (Día, Hora). Selecciona el
              grupo al cuál deseas asistir.{' '}
            </p>
          )}
          El facilitador del seminario se estará contactando para más información; pero si tienes
          alguna inquietud puedes comunicarte al{' '}
          <a
            href="https://api.whatsapp.com/send?phone=573165295200"
            target="_blank"
            rel="noreferrer"
          >
            Whatsapp 3165295200
          </a>
          .
        </p>
      )}
    </div>
  );
};

const CoupleGroupRegistration = (props) => {
  const { inputs, setInputs, setStep, person, next, strictNext, leaderView, loading, Step } = props;
  const [errorInputs, set_errorInputs] = React.useState({});
  const [getMemberLoading, set_getMemberLoading] = React.useState(false);

  const verifyForm = () => {
    let mandatoryInputs = ['name', 'lastName', 'email', 'gender', 'identification', 'phone'];

    let inputsWithErrors = {};

    if (inputs?.[person]?.['identification'] && inputs?.[person]?.['identification'].length <= 5) {
      inputsWithErrors = {
        ...inputsWithErrors,
        identification: !inputs?.[person]?.['identification'].length >= 5,
      };
    }

    if (inputs?.[person]?.['phone'] && inputs?.[person]?.['phone'].length <= 10) {
      inputsWithErrors = {
        ...inputsWithErrors,
        phone: !inputs?.[person]?.['phone'].length >= 10,
      };
    }

    if (!leaderView) {
      ['activityName'].forEach((input) => {
        inputsWithErrors = {
          ...inputsWithErrors,
          [input]: !inputs?.[input],
        };
      });
    }

    mandatoryInputs.forEach((input) => {
      inputsWithErrors = {
        ...inputsWithErrors,
        [input]: !inputs?.[person]?.[input],
      };
    });

    set_errorInputs(inputsWithErrors);
    return Object.values(inputsWithErrors).includes(true);
  };

  React.useEffect(() => {
    if (person === 'person1')
      setInputs({
        ...inputs,
        [person]: {
          ...(inputs?.[person] || {}),
          identification: inputs.identification,
          phone: inputs.phone,
        },
      });
  }, []);

  const nextStep = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    if (
      person === 'person2' &&
      inputs?.person1?.identification === inputs?.person2?.identification
    ) {
      return Swal.fire('No puedes registrar a la misma persona como pareja');
    }
    if (person === 'person2' && inputs?.person1?.gender === inputs?.person2?.gender) {
      return Swal.fire('La pareja no puede tener el mismo genero');
    }
    if (
      (!(inputs.activityName?.includes('Parejas') || inputs.activityName === 'Para_Siempre._') ||
        inputs.civil === 'Soltero') &&
      !strictNext
    )
      setStep('selectLeaders');
    else setStep(next);
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({
      ...inputs,
      [person]: { ...(inputs?.[person] || {}), [name]: value },
    });
  };

  const handleDropDown = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const getAMember = (e) => {
    e.preventDefault();
    const { identification, phone } = inputs?.[person] || {};

    if (!identification || !phone) return Swal.fire('Para buscar escribe la cedula y el celular');

    set_getMemberLoading(true);

    membersForms
      .getAMember({ identification, phone })
      .then((response) => {
        if (response.data.success) {
          set_getMemberLoading(false);
          if (response.data.finded && response.data.match) {
            setInputs({
              ...inputs,
              [person]: { ...(inputs?.[person] || {}), ...response.data.guest },
            });
          } else {
            if (response.data.message) Swal.fire(response.data.message);
          }
        }
      })
      .catch((error) => {
        set_getMemberLoading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  };

  const moreInfo = () => {
    if (!leaderView) {
      return (
        <div>
          {!window.location.pathname.includes('crecer') && (
            <>
              <p style={{ margin: '10px 0 0 0' }}>Selecciona tu categoria de genero y edad</p>
              <p style={{ margin: '0 0 2px 0' }}>
                <small>Desliza para ver todas las opciones</small>
              </p>
              <Select
                style={{ width: '45%', margin: '5px' }}
                placeholder={'Categoría'}
                className={errorInputs['activityName'] ? 'error' : ''}
                onChange={handleDropDown}
                disabled={Step === 'person2'}
                value={inputs.activityName || ''}
                name={'activityName'}
                id={'activityName'}
                options={groupCategories.map((opt) => {
                  return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
                })}
              />
            </>
          )}
          {inputs.activityName === 'Para_Siempre._' && (
            <div style={{ margin: '0 0 20px 0' }}>
              {formSelectService({
                Select,
                handler: handleDropDown,
                label: 'Estado civil',
                name: 'civil',
                disabled: person === 'person2',
                value: inputs.civil || '',
                errorInputs,
                options: ['Casado', 'Union libre', 'Novios', 'Soltero'],
              })}
            </div>
          )}
          {habeasData}
        </div>
      );
    }
    return <></>;
  };

  return (
    <form onSubmit={(e) => nextStep(e)} className="Registration__form">
      {inputs.activityName?.includes('._') && seminaryInfo(inputs.activityName)}
      {person === 'person1' ? (
        <h2 style={{ margin: '25px 0 10px 0' }}>Llena {leaderView ? 'los' : 'tus'} datos</h2>
      ) : (
        <h2>Llena los datos de {leaderView ? 'la' : 'tu'} pareja</h2>
      )}
      {Object.values(errorInputs).includes(true) && (
        <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
      )}
      <div>
        <Button
          loading={getMemberLoading}
          disabled={getMemberLoading}
          onClick={(e) => getAMember(e)}
        >
          Llenar información
        </Button>
        <p style={{ fontSize: '12px', margin: 0 }}>
          Ingresa el celular y cédula para traer la información que hay en el sistema asociados.
        </p>
      </div>
      <Form
        saveTag={'Continuar '}
        Attendant={inputs?.[person] || {}}
        userprofile={true}
        handleInputs={handleInputs}
        handleDropDown={handleDropDown}
        errorInputs={errorInputs}
        sendInForm={!!next}
        showGender
        showBirthdate
        loadedData={loading}
        submit={(e) => nextStep(e)}
        moreInfo={moreInfo}
      />
    </form>
  );
};

CoupleGroupRegistration.defaultProps = {
  moreInfo: () => <div></div>,
  loading: false,
};

export default CoupleGroupRegistration;
