import { useState, useEffect, useCallback } from 'react';
import { DashboardCrecerComponent } from './styles/DashboardCrecerComponent';
import membersProgressService from '../../../services/adminService';
import reportService from '../../../services/reportService';
import Swal from 'sweetalert2';
import LineChartSection from '../utils/LineChartSection';
import PieChartComponent from '../utils/PieChartComponent';
import { getTotalsGraph } from './Groups';

const Crecer = () => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(true);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(false);
  const [startDate, setStartDate] = useState('2024-06-01');
  const [endDate, setEndDate] = useState('2024-12-31');
  const [classesAttended, setClassesAttended] = useState('16');
  const [classesAttendedInRange, setClassesThisSem] = useState('1');
  const [step, setStep] = useState('vida');
  const [stepAtt, setStepAtt] = useState('nuevos');
  const [allPeople, setAllPeople] = useState([]);
  const [byClass, setByClass] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [filterByClass, setFilterByClass] = useState([false]);

  const steps = ['Todos', 'vida', 'influencia', 'encuentro', 'bautismo'];
  const stepsAttendance = ['nuevos', 'vida', 'influencia', 'encuentro', 'bautismo'];
  const width = 600;
  let lines = 10;
  const lineThickness = 40;
  const baseWidth = 350;
  const spacing = baseWidth / lines / 2;

  const filterGenders = (attendants) => {
    const total = attendants.length;
    const men = attendants.filter((attendant) => attendant.Guest.gender === 'Masculino').length;
    const women = attendants.filter((attendant) => attendant.Guest.gender === 'Femenino').length;
    const unknow = attendants.filter(
      (attendant) =>
        !attendant.Guest.gender ||
        (attendant.Guest.gender !== 'Masculino' && attendant.Guest.gender !== 'Femenino')
    ).length;
    const percentageMen = ((men / total) * 100).toFixed(1);
    const percentagewoman = ((women / total) * 100).toFixed(1);
    const percentageunknow = ((unknow / total) * 100).toFixed(1);
    const genders = [
      { name: 'Hombres', value: men, percentage: percentageMen },
      { name: 'Mujeres', value: women, percentage: percentagewoman },
      { name: 'Sin datos', value: unknow, percentage: percentageunknow },
    ];
    return genders;
  };

  const allCrecerAttendance = () => {
    setLoading(true);
    if (step === 'Todos') {
      membersProgressService
        .getAllCrecerSteps({ startDate, endDate, classesAttended, classesAttendedInRange })
        .then((res) => {
          const bautismo = filterGenders(res.data.allSteps.bautismo);
          const conectar = filterGenders(res.data.allSteps.conectar);
          const encuentro = filterGenders(res.data.allSteps.encuentro);
          const influencia = filterGenders(res.data.allSteps.influencia);
          const vida = filterGenders(res.data.allSteps.vida);
          const classVida = filterGenders(res.data.allStepsFilteredByClasses.vida);
          const classInfluencia = filterGenders(res.data.allStepsFilteredByClasses.influencia);

          const all = [
            { name: 'Conectar', value: conectar },
            { name: 'Encuentro', value: encuentro },
            { name: 'Bautismo', value: bautismo },
            { name: 'Vida', value: vida, filter: classVida },
            {
              name: 'Influencia',
              value: influencia,
              filter: classInfluencia,
            },
          ];

          setAllSteps(all);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      membersProgressService
        .getAllCrecerAttendance({
          step,
          startDate,
          endDate,
          classesAttended,
          classesAttendedInRange,
        })
        .then((response) => {
          const progressData = response.data || [];
          const attendants = progressData.attendants || [];
          const filterByClass = progressData.filteredByClasses || [];

          const genders = filterGenders(attendants);
          setAllPeople(genders);

          if (filterByClass.length !== 0) {
            setFilterByClass(true);
            setByClass(filterGenders(filterByClass));
          } else {
            setFilterByClass(false);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchData = () => {
    setLoading(true);
    if (stepAtt === 'nuevos') {
      let tryingToJoinList = [];
      let activeMembersList = [];

      membersProgressService.getNewMembersInGroups({ startDate, endDate }).then((response) => {
        const data = response.data.groupMembers;

        // Filtrar los que tienen tryingToJoin en true
        tryingToJoinList = data.filter((member) => member.tryingToJoin === true);

        // Filtrar los que tienen activeMember en true
        activeMembersList = data.filter((member) => member.activeMember === true);
      });
      membersProgressService
        .getNewMembersProgress({ startDate, endDate })
        .then((response) => {
          const progressData = response.data || {};

          const funnelData = [
            { name: 'Nuevos Miembros', value: progressData.newMembers?.length || 0 },
            { name: 'Conectar', value: progressData.conectar?.length || 0 },
            { name: 'Encuentro', value: progressData.encuentro?.length || 0 },
            { name: 'Bautismo', value: progressData.bautismo?.length || 0 },
            { name: 'solicitó grupo', value: tryingToJoinList.length || 0 },
            { name: 'en grupo', value: activeMembersList.length || 0 },
            { name: 'Vida', value: progressData.vida?.length || 0 },
            { name: 'Seminarios', value: progressData.seminary?.length || 0 },
            { name: 'Influencia', value: progressData.influencia?.length || 0 },
          ];

          setData(funnelData);
        })
        .catch((error) => {
          console.error('Error al obtener el progreso de los miembros:', error);
        })
        .finally(() => {
          setLoading(false);
          setSearch(false);
        });
    } else {
      const step = stepAtt;
      membersProgressService
        .crecerProgress({ startDate, endDate, step })
        .then((res) => {
          const progressData = res.data;

          const stepsMap = {
            encuentro: ['Encuentro', 'Bautismo', 'Vida', 'Influencia'],
            bautismo: ['Bautismo', 'Vida', 'Influencia'],
            vida: ['Vida', 'Influencia'],
            influencia: ['Influencia', 'líderes activos'],
          };

          const funnelData = (stepsMap[stepAtt] || []).map((step) => ({
            name: step,
            value:
              progressData[
                step.toLowerCase() === 'líderes activos' ? 'leaders' : step.toLowerCase()
              ]?.length || 0,
          }));

          setData(funnelData);
        })
        .finally(() => {
          setLoading(false);
          setSearch(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function getAttendantReport() {
    setLoading(true);

    let body = {
      startDate,
      endDate,
      step,
      classesAttended,
      classesAttendedInRange,
    };

    let URL = `/crecer/getAllCrecerAttendanceReports`;
    let fileName = `InformeAsistenciaCrecer_${step}.xlsx`;

    reportService
      .getReport({ URL, fileName, body })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        setLoading(false);
      });
  }

  const stepsData = {
    nuevos: { title: 'Nuevos Miembros', lines: [...Array(9).keys()] },
    encuentro: { title: 'Encuentro', lines: [...Array(4).keys()] },
    bautismo: { title: 'Bautismo', lines: [...Array(3).keys()] },
    vida: { title: 'Vida', lines: [...Array(2).keys()] },
    influencia: { title: 'Influencia', lines: [...Array(2).keys()] },
  };

  const graphFilteredAttendanceSubt = useCallback(
    (people) =>
      `Total con minimo ${classesAttended} clases${
        Number(classesAttendedInRange) !== 1
          ? ` y ${classesAttendedInRange} clases dentro del rango`
          : ''
      }: ${getTotalsGraph(people)}`,
    [loading]
  );

  return (
    <DashboardCrecerComponent>
      <h2 className="h2Title">Crecer</h2>

      <div>
        <button onClick={() => setActiveTab(true)} className={`tabs ${activeTab ? 'active' : ''}`}>
          Asistencia
        </button>
        <button
          onClick={() => setActiveTab(false)}
          className={`tabs ${!activeTab ? 'active' : ''}`}
        >
          Reportes
        </button>
      </div>

      {activeTab && (
        <>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <label className="labels">Paso:</label>
              <select
                value={stepAtt}
                onChange={(e) => {
                  setStepAtt(e.target.value);
                  setSearch(true);
                }}
                style={{
                  width: '150px',
                }}
                className="inputs"
              >
                {stepsAttendance.map((step, index) => (
                  <option key={index} value={step}>
                    {step}
                  </option>
                ))}
              </select>
              <label className="labels">Desde:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="inputs"
              />

              <label className="labels">Hasta:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="inputs"
              />

              <button className="button" onClick={fetchData}>
                Consultar
              </button>
            </div>
          </div>

          {loading && (
            <div className="containerSpinner">
              <div className="spinner"></div>
            </div>
          )}
          {search && (
            <>
              <div className="whiteSpace">
                <p>Click en consultar</p>
              </div>
            </>
          )}
          {!loading && !search && (
            <>
              <div style={{ width: '100%', height: '100%' }}>
                <LineChartSection
                  title={stepsData[stepAtt].title}
                  subtitle={
                    stepsData[stepAtt].title === 'Vida' || stepsData[stepAtt].title === 'Influencia'
                      ? `Estudiantes de ${stepsData[stepAtt].title}, con al menos 14 clases vistas en el rango de fechas`
                      : 'En vida e influencia, se considera a las personas que a este momento han visto al menos 1 clase en el rango de fechas'
                  }
                  linesArray={stepsData[stepAtt].lines}
                  width={width}
                  baseWidth={baseWidth}
                  spacing={spacing}
                  lineThickness={lineThickness}
                  data={data}
                />
              </div>
            </>
          )}
        </>
      )}

      {!activeTab && (
        <>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <label className="labels">Paso:</label>
              <select
                value={step}
                onChange={(e) => setStep(e.target.value)}
                style={{
                  width: '150px',
                }}
                className="inputs"
              >
                {/* Mapeamos la lista de 'steps' para generar las opciones */}
                {steps.map((step, index) => (
                  <option key={index} value={step}>
                    {step} {/* Aquí se muestra el valor de cada opción */}
                  </option>
                ))}
              </select>
              <div>
                <div>
                  <label className="labels">Desde:</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="inputs"
                  />
                </div>
                <div>
                  <label className="labels">Hasta:</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="inputs"
                  />
                </div>
              </div>
              <div>
                {step !== 'encuentro' && step !== 'bautismo' && (
                  <div>
                    <label style={{ marginRight: '8px' }}>Cantidad de clases:</label>
                    <input
                      type="text"
                      value={classesAttended}
                      onChange={(e) => setClassesAttended(e.target.value)}
                      style={{
                        borderRadius: '10px',
                        border: '1px solid #242323',
                        padding: '5px',
                        width: '80px',
                      }}
                    />
                  </div>
                )}
                {step !== 'encuentro' && step !== 'bautismo' && (
                  <div>
                    <label style={{ marginRight: '8px' }}>Clases dentro del rango:</label>
                    <input
                      type="text"
                      value={classesAttendedInRange}
                      onChange={(e) => setClassesThisSem(e.target.value)}
                      style={{
                        borderRadius: '10px',
                        border: '1px solid #242323',
                        padding: '5px',
                        width: '80px',
                      }}
                    />
                  </div>
                )}
              </div>
              <button className="button" onClick={allCrecerAttendance}>
                Consultar
              </button>

              {step !== 'Todos' && (
                <button className="button" onClick={getAttendantReport}>
                  Descargar
                </button>
              )}
            </div>
          </div>
          {loading && (
            <div className="containerSpinner">
              <div className="spinner"></div>
            </div>
          )}

          {!loading &&
            (step !== 'Todos' ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  marginTop: '40px',
                }}
              >
                <PieChartComponent
                  title={'Asistentes en el rango de fechas'}
                  subtitle={`Total con al menos 1 clase en el rango: ${getTotalsGraph(allPeople)}`}
                  data={allPeople}
                  outerRadius={80}
                  width={400}
                  nameKey="name"
                />

                {filterByClass && (
                  <PieChartComponent
                    title={'Asistentes con el minimo de asistencias'}
                    subtitle={graphFilteredAttendanceSubt(byClass)}
                    data={byClass}
                    outerRadius={80}
                    width={450}
                    nameKey="name"
                  />
                )}
              </div>
            ) : (
              <div className="container-1">
                <h3>Todos los pasos </h3>
                {allSteps.map((step, index) => (
                  <div
                    key={index}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    <h3>{step.name}</h3>
                    <PieChartComponent
                      title={'Asistentes en el rango de fechas'}
                      subtitle={`Total con al menos 1 clase en el rango: ${getTotalsGraph(
                        step.value
                      )}`}
                      data={step.value}
                      outerRadius={80}
                      width={450}
                      nameKey="name"
                    />

                    {step.filter && (
                      <PieChartComponent
                        title={'Asistentes con el minimo de asistencias'}
                        subtitle={graphFilteredAttendanceSubt(step.filter)}
                        data={step.filter}
                        outerRadius={80}
                        width={450}
                        nameKey="name"
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
        </>
      )}
    </DashboardCrecerComponent>
  );
};

export default Crecer;
