import jwtDecode from 'jwt-decode';
import moment from 'moment';
export const weekdays = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

export const localhostname = 'localhost';

export const formatCurrency = (number) => {
  return `$ ${new Intl.NumberFormat('en', { maximumSignificantDigits: 15 }).format(number)}`;
};

export const dayInTZ = (date, format = 'DD-MM-YYYY') =>
  moment(date).tz('America/Bogota').format(format);

export const avoCaptConstructor = (num) => parseInt(String(num)?.substring(1, 5)) * 2;

export const boolSiNo = (val) => (val ? 'Sí' : 'No');

export const s = (arr = [], letterPlural = 's', letterSingular = '') =>
  arr?.length !== 1 ? letterPlural : letterSingular;

export const scrollUp = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const scrollTo = (top, left) => window.scrollTo({ top, left, behavior: 'smooth' });

export const renderNumberOfTimes = (num) => {
  let times = [];
  for (let i = 1; i <= num; i++) {
    times.push(i);
  }
  return times;
};

export const kindgrouplist = ['Equipo_de_Líderes', 'Grupo_Discipulado', 'Grupo_Seminario'];

export const counselingProfessionalFields = ['Psicologia', 'Consejero'];

export const experienceStaffRoles = [
  'Director Producción',
  'Líder producción técnica',
  'Pastor',
  'Asistente',
  'Preside',
  'Seguridad',
  'Pastores Generaciones',
];

export const comunidadSocialRoles = [
  'Habitantes de Calle',
  'Geriátricos',
  'Fundaciones',
  'Hospitales',
  'Carcel',
];

export const crecerRoles = ['Miercoles_A', 'Miercoles_B'];

export const experienceVolunteersSections = (serviceArea = '') => [
  ...(serviceArea === 'Staff' ? experienceStaffRoles : []),
  ...(serviceArea === 'Comunidad Social' ? comunidadSocialRoles : []),
  ...(serviceArea === 'Crecer' ? crecerRoles : []),
  'Domingo_A',
  'Domingo_B',
  'Sabado_A',
  'Sabado_B',
  ...(serviceArea.includes('eneraciones') ? ['En_entrenamiento'] : []),
  'No_Aplica',
  'Primario',
];

export const experienceVolunteersRoles = ['Coordinador', 'Encargado', 'Encargado jr', 'Voluntario'];

export const leaderCategories = [
  'Chicas_cyphers_(14-17)',
  'Chicas_jovenes_youth',
  'Chicas_Jovenes',
  'Chicos_cyphers_(14-17)',
  'Chicos_jovenes_youth',
  'Chicos_Jovenes',
  'Hombres',
  'Mixto_Adultos',
  'Mixto_cyphers_(14-17)',
  'Mixto_jovenes_youth',
  'Mixto_Jovenes',
  'Mujeres',
  'Parejas',
];

export function generateTimeArray(startTime = 0, endTime = 24) {
  const times = [];
  for (let hour = startTime; hour < endTime; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      times.push(`${formattedHour}:${formattedMinute}`);
    }
  }
  return times;
}

export const groupCategories = [
  'Niños_79_(7_a_9_años)',
  'Niñas_79_(7_a_9_años)',
  'Mixto_79_(7_a_9_años)',
  'Niñas_Nxt_(10_a_13_años)',
  'Niños_Nxt_(10_a_13_años)',
  'Mixto_Nxt_(10_a_13_años)',
  'Chicas_Cyphers_(14_a_17_años)',
  'Chicos_Cyphers_(14_a_17_años)',
  'mixto_cyphers_(14_a_17_años)',
  'Hombres_jóvenes_Youth_(18-25_años)',
  'Mujeres_jóvenes_Youth_(18-25_años)',
  'Youth_Mixto_(18_-_25_años)',
  'Hombres_Adultos_(26_a_35_años)',
  'Mixtos_Adultos_(26_a_40_años)',
  'Mujeres_Jóvenes_Adultas_(26_a_35_años)',
  'Hombres_Adultos_(36_a_45_años)',
  'Mujeres_Adultas_(36_a_45_años)',
  'Hombres_Adultos_(46_a_55_años)',
  'Mujeres_Adultas_(46_a_55_años)',
  'Mixtos_Adultos_(41_años_en_adelante)',
  'Hombres_Adultos_(Más_de_56_años)',
  'Mujeres_Adultas_(Más_de_56_años)',
  'Parejas_(Casadas/_Unión_Libre_)',
];

export const seminary = ['Para_Siempre._', 'Transformacion_Empresarial._'];

export const comunidadProcess = ['Crecer_Encuentro', 'Crecer_Vida', 'Crecer_Influencia'];

export const clickableStyles = {
  cursor: 'pointer',
  color: 'gray',
  textDecoration: 'underline',
};

export const secondaryServices = [
  'Bautizos',
  'Noche_de_Parejas',
  'Noche_de_Influencia',
  'Noche_con_el_Espiritu_Santo',
  'Entrenamiento_para_Lideres',
  'Presentacion_de_Bebes',
  'Encuentro_Presencial.',
  'Youth_Night',
  'Cyphers',
];

export const churchServices = ['Auditorio_Principal', 'Servicio_Especial', ...secondaryServices];

export const generacionesServices = [
  'Generaciones_de_0_a_3',
  'Generaciones_de_4_a_6',
  'Generaciones_de_7_a_9',
  'Generaciones_de_10_a_13',
];

export const copytoClipboard = ({ content, Swal }) => {
  try {
    const copyText = document.createElement('textarea');
    copyText.value = content;
    document.body.appendChild(copyText);
    copyText.select();
    copyText.setSelectionRange(0, 999999);
    navigator.clipboard.writeText(copyText.value);
    document.body.removeChild(copyText);
    Swal.fire('Copiado!', content);
  } catch {
    Swal.fire('Hubo un error copiando el siguiente texto:', content);
  }
};

export const CheckinURL = (event) => {
  let eventURL = '';
  if (event === 'crecer') {
    eventURL = `crecer/crecerAttendance`;
  }
  if (event === 'Auditorio_Principal') {
    eventURL = `event/church-service`;
  }
  return eventURL;
};

export const profileImage = (guest) => {
  if (guest.photo) return guest.photo;
  if (guest.gender === 'Masculino')
    return 'https://react.semantic-ui.com/images/avatar/small/matthew.png';
  if (guest.gender === 'Femenino')
    return 'https://react.semantic-ui.com/images/avatar/small/lindsay.png';
};

export const chPerm = (profile, strict = false) => {
  try {
    const userToken = localStorage.getItem('userToken');
    let currentUser = {};
    currentUser = jwtDecode(userToken);
    if (currentUser && currentUser.status) {
      return (
        currentUser.status.find((perm) => profile.includes(perm)) ||
        (currentUser.status.includes('totalAccess') && !strict)
      );
    }
  } catch {}
  return false;
};

export function addHours(hours, time = new Date()) {
  let d = new Date(time);
  return d.setTime(d.getTime() + hours * 60 * 60 * 1000);
}

export function countAnswers(obj) {
  try {
    let allLetters = [...new Set(Object.values(obj).map((letter) => letter.letter))];
    let answersGathered = {};
    allLetters.forEach((letter) => {
      answersGathered[letter] = 0;
    });
    Object.values(obj).forEach((answer) => {
      answersGathered[answer.letter] = answersGathered[answer.letter] + answer.grade;
    });

    answersGathered = Object.entries(answersGathered);

    answersGathered = answersGathered.map((ans) => {
      return { letter: ans[0], grade: ans[1] };
    });
    return sortObjects(answersGathered, 'grade');
  } catch (error) {
    return [{}];
  }
}

export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const sortObjects = (objs, item, order = 'asc') => {
  if (order === 'asc')
    return objs.sort((a, b) => (a[item] > b[item] ? +1 : b[item] > a[item] ? -1 : 0));
  return objs.sort((a, b) => (a[item] > b[item] ? -1 : b[item] > a[item] ? +1 : 0));
};

export const sortArray = (arr, order = 'asc') => {
  if (order === 'asc') return arr.sort((a, b) => (a > b ? +1 : b > a ? -1 : 0));
  return arr.sort((a, b) => (a > b ? -1 : b > a ? +1 : 0));
};

export const churchMinistries = [
  'Adecuación',
  'Anfitriones',
  'Apoyo Logistico',
  'Bienvenido a Casa',
  'Camaras',
  'Comunidad Music',
  'Comunidad Social',
  'Conectate Pos',
  'Conectate',
  'Coordinador de Ofrenda',
  'Crecer',
  'Cuidado de ninos pastorales',
  'Decoracion y Ambientacion',
  'Entrenador Experiencia',
  'Equipo de Danza',
  'Equipo de Discipulado',
  'Equipo de Redes Sociales',
  'Equipo de Teatro',
  'Equipo de Traduccion',
  'Equipo TI',
  'Generaciones 46',
  'Generaciones 79',
  'Generaciones Babies',
  'Generaciones Cyphers',
  'Generaciones Next',
  'Informate',
  'Luces',
  'Mantenimiento',
  'Music Generaciones',
  'Oracion',
  'Primeros Auxilios',
  'Produccion',
  'Proyeccion',
  'Sala de Experiencia',
  'Sonido',
  'Staff',
  'Viento Fresco',
  'Youth',
];

export const errorInput = ({ errorInputs, input = {} }) => {
  errorInputs = { ...errorInputs, [input.name]: !Boolean(input.value) };
  return errorInputs;
};

export const deleteForm = async ({ Swal, subtitle = '', func = () => {} }) => {
  const { value: text } = await Swal.fire({
    title: 'Eliminar',
    input: 'textarea',
    text: subtitle,
    inputPlaceholder: 'Escribe la razón por la que vas a eliminar este campo',
    inputAttributes: {
      'aria-label': 'Razón de eliminación',
    },
    showCancelButton: true,
  });

  const successAlert = () => Swal.fire('Eliminado exitosamente');

  if (text) await func({ text, successAlert });
};

export const selectArea = ({
  Select,
  options,
  label,
  name,
  value,
  loading = false,
  errorInputs = {},
  handler,
}) => (
  <Select
    placeholder={label}
    style={{
      display: 'block',
      margin: ' 7px 0',
    }}
    value={value}
    className={errorInputs[name] ? 'error' : ''}
    id={name}
    onChange={handler}
    name={name}
    loading={loading}
    options={options.map((a) => {
      return { key: a, value: a, text: a };
    })}
  />
);

export const textArea = ({
  TextArea,
  label,
  name,
  disabled = false,
  value,
  style = {},
  handler,
  defaultValue,
  loading = false,
  errorInputs = {},
}) => {
  return (
    <div id="transversal">
      <TextArea
        placeholder={label}
        name={name}
        id={name}
        error={errorInputs[name]}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        style={{
          ...style,
          padding: '10px',
          maxWidth: '700px',
          minWidth: '80%',
        }}
        loading={loading}
        onChange={handler}
        className={`Registration__input Registration__input-${name} ${
          errorInputs[name] ? 'error' : ''
        }`}
      />
    </div>
  );
};

export const formSelect = ({
  Select,
  name,
  label,
  value,
  errorInputs = {},
  disabled = false,
  options,
  handler,
}) => (
  <Select
    placeholder={label}
    className={errorInputs[name] ? 'error' : ''}
    onChange={handler}
    value={value}
    disabled={disabled}
    id={name}
    name={name}
    options={options.map((a) => {
      return { key: a, value: a, text: a };
    })}
  />
);

export const eventSlugnameTrim = (eventSlugname = '') => {
  if (Boolean(eventSlugname) && typeof eventSlugname === 'string') {
    return eventSlugname.replace(/_/gi, ' ');
  }
  return eventSlugname;
};

export const formSelectService = ({
  Select,
  name,
  label,
  value,
  loading = false,
  options,
  style = {},
  handler,
  disabled,
  errorInputs = {},
}) => (
  <Select
    placeholder={label}
    onChange={handler}
    disabled={disabled}
    value={value}
    name={name}
    id={name}
    loading={loading}
    style={style}
    className={`Registration__input Registration__input-${name} ${
      errorInputs[name] ? 'error' : ''
    }`}
    options={options.map((opt) => {
      return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
    })}
  />
);

export const formInput = ({
  Input,
  label,
  name,
  disabled = false,
  value,
  handler,
  loading = false,
  defaultValue,
  errorInputs = {},
  type = 'text',
}) => (
  <Input
    placeholder={label}
    name={name}
    error={errorInputs[name]}
    value={value}
    disabled={disabled}
    loading={loading}
    onChange={handler}
    defaultValue={defaultValue}
    type={type}
    className={`Registration__input Registration__input-${name} ${
      errorInputs[name] ? 'error' : ''
    }`}
  />
);

export const uniqueElements2Arrays = (array1, array2) =>
  array1.filter(function (obj) {
    return array2.indexOf(obj) === -1;
  });

export const changeArrayLine = (line, allArrays, set_allArrays, id = 'id') => {
  let modArrays = allArrays.map((Array) => {
    if (parseInt(Array[id]) === parseInt(line[id])) return { ...Array, ...line };
    return Array;
  });
  set_allArrays(modArrays);
};

export const changeDocTitle = (title = '', churchName = 'Comunidad Info ') => {
  if (title) title = `| ${title}`;
  try {
    if (document) {
      document.title = `${churchName}${title}`;
    }
  } catch {}
};

export const fileChangedHandler = (
  event,
  set_image,
  Swal,
  Resizer,
  image,
  set_imagePreview = () => {}
) => {
  if (!!event.target.files[0]) {
    const fileExt = event.target.files[0].name.split('.').pop();
    if (!['jpg', 'jpeg', 'png'].includes(fileExt.toLowerCase())) {
      return Swal.fire('Solo puedes subir imagenes en JPG, JPEG o PNG');
    }
    const targetID = event.target.id;
    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        targetID.includes('document') ? 600 : 450,
        targetID.includes('document') ? 600 : 450,
        'JPEG',
        100,
        0,
        (uri) => {
          set_image(uri);
        },
        'file',
        200,
        200
      );
    } catch (err) {
      if (image.files[0].size > 5000000) {
        return Swal.fire('Solo puedes subir imagenes de máximo 5 Mb');
      }
    }
    set_imagePreview(URL.createObjectURL(event.target.files[0]));
  }
};

export const age = (birthdate) => moment().diff(birthdate, 'years');

export const classDictionary = (className) => {
  return className.replace(/class/gi, 'Clase ');
};
