import { css } from '@emotion/react';

export const GlobalComponent = css`
  h1,
  h2 {
    margin: 0;
  }
  p {
    small {
      font-size: 0.75em;
    }
  }
  .printable {
    padding: 30px;
  }
  .sigCanvas {
    border: 1px dotted black;
    &.error {
      border: 3px dotted red;
    }
  }
  .profilePhoto {
    width: 145px;
    height: 170px;
    display: block;
    padding: 60px 5px;
    text-align: center;
    background-size: cover;
    background-position: center;
    margin: 20px 0;
    color: rgba(255, 0, 0, 0);
    &.upload,
    &.nophoto {
      color: black;
    }
    &.upload,
    &.change {
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
    &.nophoto {
      border: 1px solid black;
    }
  }

  .button,
  .profilePhoto {
    &.error {
      background-color: red;
      color: white;
    }
  }

  .line-break {
    white-space: pre-wrap;
  }

  .SingleDatePicker {
    .SingleDatePicker_picker {
      z-index: 10;
    }
  }
`;
