import { useEffect, useState } from 'react';
import { fileChangedHandler } from '../../helpers';
import Resizer from 'react-image-file-resizer';
import Swal from 'sweetalert2';

const UploadProfilePhoto = (props) => {
  const [imagePreview, set_imagePreview] = useState(null);
  const {
    name,
    labelDescription,
    filling,
    imageFile,
    errorInputs = {},
    inputs,
    set_image,
    isPrinting,
  } = props;

  useEffect(() => {
    if (props.inputs.photo && imagePreview === null) set_imagePreview(props.inputs.photo);
  }, [props.inputs]);

  const uploadProfilePicInput = (formID, secString) => {
    return (
      <>
        <label
          className={`profilePhoto profile-image ${!!errorInputs[formID] ? 'error' : ''} ${
            Boolean(!imagePreview && filling) ? 'upload' : ''
          } ${Boolean(filling && imagePreview) ? 'change' : ''} ${
            Boolean(!filling && !imagePreview) ? 'nophoto' : ''
          }`}
          htmlFor={formID}
          style={{ backgroundImage: `url(${imagePreview})` }}
        >
          {!imagePreview && filling && 'Sube ' + secString}
          {filling && imagePreview && 'Cambiar ' + secString}
          {!filling && !imagePreview && 'No hay ' + secString}
        </label>
        {filling && (
          <input
            onChange={(event) =>
              fileChangedHandler(event, set_image, Swal, Resizer, imageFile, set_imagePreview)
            }
            type="file"
            id={formID}
            accept=".jpg,.jpeg,.png"
            style={{ display: 'none' }}
          />
        )}
        {inputs['photo'] && !filling && (
          <button
            onClick={() => window.open(inputs['photo'])}
            className={`ui compact button ${isPrinting ? 'hidden' : ''}`}
          >
            {`Descargar ${secString}`}
          </button>
        )}
      </>
    );
  };

  return <>{uploadProfilePicInput(name, labelDescription)}</>;
};

export default UploadProfilePhoto;
