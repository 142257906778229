import styled from '@emotion/styled';

export const DashboardProfileComponent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafdff;
  border-radius: 20px;

  /* Adaptación para dispositivos móviles */
  @media (max-width: 768px) {
    border-radius: 20px 20px 0px 0px;
  }

  .profile-text {
    color: #191919;
  }

  .greeting {
    font-size: 25px;
    margin: 0px;
  }

  .columnsProfile {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    /* Adaptación para dispositivos móviles */
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .columnProfile {
    flex: 1;
    padding: 10px;
    border: 1px solid #ece8e3;
    border-radius: 5px;
    background-color: #ece8e3;
    margin-left: 20px;

    /* Adaptación para dispositivos móviles */
    @media (max-width: 768px) {
      margin-left: 0;
      margin-bottom: 20px;
      width: 100%;
      background-color: white;
      border: 0px solid;
    }
  }

  .profile-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px auto;

    /* Adaptación para dispositivos móviles */
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .image-wrapper {
    width: 350px;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
    .upload-photo {
      top: 0;
      position: absolute;
      width: 150px;
      height: 50px;
      background: rgb(0, 28, 75);
      color: white;
      padding: 5px;
      cursor: pointer;
      border-radius: 5px;
      &.change {
        display: none;
      }
    }
    &:hover .upload-photo {
      display: block;
    }
    /* Adaptación para dispositivos móviles */
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: opacity 0.3s ease;
    image-rendering: auto;

    /* Adaptación para dispositivos móviles */
    @media (max-width: 768px) {
      height: 350px;
      border-radius: 10px;
    }
  }

  .profile-field {
    color: rgb(68, 68, 68);

    label {
      display: block;
      font-size: 15px;
      color: #191919;
      padding-left: 5px;
    }

    .field-value {
      display: inline-block;
      padding: 10px;
      border: 1px solid #edd4be;
      border-radius: 10px;
      background-color: #fff;
      width: 100%;
      margin-bottom: 10px;
      color: #70706d;
    }
  }

  @media (max-width: 768px) {
    .overlay {
      display: none; /* Oculta el contenedor overlay en dispositivos móviles */
    }
  }
`;
