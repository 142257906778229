import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from 'semantic-ui-react';

function NotificationModal(props) {
  const { header, message, open, onClose } = props;

  return (
    <Modal size="tiny" onClose={onClose} open={open}>
      <ModalHeader>{header}</ModalHeader>
      <ModalContent>
        <ModalDescription>
          <p>{message}</p>
        </ModalDescription>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Cerrar</Button>
      </ModalActions>
    </Modal>
  );
}

export default NotificationModal;
