import { useRef, useContext, useEffect, useState } from 'react';
import { Tab } from 'semantic-ui-react';
import './styles/DashboardCrecerComponent.js';
import { DashboardCrecerComponent } from './styles/DashboardCrecerComponent.js';
import { GuestDashboardContext } from './guestDashboardContext.js';
import { dayInTZ } from '../../helpers/index.js';
import CertificateGenerator from './CertificateGenerator.jsx';
import NotificationModal from '../../helpers/NotificationModal.jsx';
import Button from '../../common/Button.jsx';

const Crecer = () => {
  const { crecerDates, guest } = useContext(GuestDashboardContext);
  const [notificationHeader, setNotificationHeader] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState(null);
  useEffect(() => {
    if (selectedCourse && pdfRef.current) {
      pdfRef.current.downloadPdf();
      setSelectedCourse(null);
    }
  }, [selectedCourse]);
  const today = new Date();
  const pdfRef = useRef();
  const attendanceStatus = (content, date) => {
    return (
      <span
        className={`status-indicator ${
          content?.[date.attendanceCode] ? 'status-true' : 'status-false'
        }`}
      >
        {content?.[date.attendanceCode] ? '✓' : '✕'}
      </span>
    );
  };

  const CrecerSteps = [
    {
      id: 1,
      name: `Paso 1 - Encuentro`,
      event: 'crecerEncuentro',
      stepsMade: guest.CrecerEncuentroSteps,
    },
    {
      id: 2,
      name: `Paso 2 - Vida`,
      event: 'crecerVida',
      stepsMade: guest.CrecerVidaSteps,
    },
    {
      id: 3,
      name: `Paso 3 - Influencia`,
      event: 'crecerInfluence',
      stepsMade: guest.CrecerInfluenceSteps,
    },
  ];

  const CrecerNextClasses = [
    {
      id: 1,
      name: `Vida`,
      event: 'crecerVida',
    },
    {
      id: 2,
      name: `Influencia`,
      event: 'crecerInfluence',
    },
  ];

  function calculateClassCompletionPercentage(data) {
    if (!data.length) return 0; // Return 0 if the array is empty

    const record = data[0]; // Get the first item in the array
    const classKeys = Object.keys(record).filter((key) => key.startsWith('class'));

    const completedClasses = classKeys.filter(
      (key) => typeof record[key] === 'string' && !isNaN(Date.parse(record[key]))
    ).length;

    return classKeys.length > 0 ? (completedClasses / classKeys.length) * 100 : 0;
  }

  const setNotificationError = (percent) => {
    setNotificationHeader('Error al descargar el certificado');
    setNotificationMessage(
      `Para descargar el certificado debe completar un mínimo del 80% del curso, actualmente se encuentra en un ${percent}%`
    );
    setNotificationOpen(true);
  };

  const handleDownloadCertified = (courseCode) => {
    switch (courseCode) {
      case 'crecerVida':
        const percentPD = calculateClassCompletionPercentage(guest.CrecerVidaSteps);
        if (percentPD >= 80) {
          setSelectedCourse(courseCode);
        } else {
          setNotificationError(percentPD);
        }
        break;
      case 'crecerInfluence':
        const percentPT = calculateClassCompletionPercentage(guest.CrecerInfluenceSteps);
        if (percentPT >= 80) {
          setSelectedCourse(courseCode);
        } else {
          setNotificationError(percentPT);
        }
        break;
      default:
        const percent = calculateClassCompletionPercentage(
          guest.Seminaries.filter((s) => s.seminary === courseCode)
        );
        if (percent >= 80) {
          setSelectedCourse(courseCode);
        } else {
          setNotificationError(percent);
        }
        break;
    }
  };

  const getSeminaryClasses = (seminary) => {
    return guest.Seminaries?.find((attendance) => attendance.seminary === seminary);
  };

  const Seminarios = [
    {
      id: 1,
      name: `Para Siempre`,
      event: 'ParaSiempre',
      stepsMade: getSeminaryClasses('ParaSiempre'),
    },
    {
      id: 2,
      name: `Proyecto Matrimonio`,
      event: 'ProyectoMatrimonio',
      stepsMade: getSeminaryClasses('ProyectoMatrimonio'),
    },
    {
      id: 3,
      name: `Escuela de héroes`,
      event: 'EscueladeHeroes',
      stepsMade: getSeminaryClasses('EscueladeHeroes'),
    },
    {
      id: 4,
      name: `Relaciones Sanas`,
      event: 'RelacionesSanas',
      stepsMade: getSeminaryClasses('RelacionesSanas'),
    },
    {
      id: 5,
      name: `Transformación Empresarial`,
      event: 'TransformacionEmpresarial',
      stepsMade: getSeminaryClasses('TransformacionEmpresarial'),
    },
  ];

  const panes = [
    {
      menuItem: 'Crecer',
      render: () => (
        <Tab.Pane className="custom-pane">
          <div className="columns">
            {!!crecerDates.length &&
              [CrecerSteps[0]].map((step) => (
                <div className="column" key={step.event}>
                  <h3
                    style={{ fontWeight: 500, color: '#00c47a' }}
                    dangerouslySetInnerHTML={{ __html: step.name }}
                  />
                  {!!step?.stepsMade?.[0]?.percentage && (
                    <>
                      <li className="step-item title virtual">
                        <span>Virtual</span>
                        <span className="percentage">{step?.stepsMade?.[0]?.percentage || 0}%</span>
                      </li>
                      <li className="step-item title">
                        <span>Presencial</span>
                        <span />
                      </li>
                    </>
                  )}
                  <ul>
                    {crecerDates
                      ?.filter((d) => d.event === step.event)
                      ?.map((date) => (
                        <li key={date.id} className="step-item">
                          <span>{date.attendanceName}</span>
                          {attendanceStatus(step.stepsMade?.[0], date)}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            {!!crecerDates.length &&
              [CrecerSteps[1], CrecerSteps[2]].map((step) => (
                <div className="column" key={step.event}>
                  <h3
                    style={{ fontWeight: 500, color: '#00c47a' }}
                    dangerouslySetInnerHTML={{ __html: step.name }}
                  />
                  <ul>
                    {!!step?.stepsMade?.[0]?.percentage && (
                      <>
                        <li className="step-item title virtual">
                          <span>Virtual</span>
                          <span className="percentage">
                            {step?.stepsMade?.[0]?.percentage || 0}%
                          </span>
                        </li>
                        <li className="step-item title">
                          <span>Presencial</span>
                          <span />
                        </li>
                      </>
                    )}
                    {crecerDates
                      ?.filter((d) => d.event === step.event)
                      ?.map((date) => (
                        <li key={date.id} className="step-item">
                          <span>{date.attendanceName}</span>
                          {attendanceStatus(step.stepsMade?.[0], date)}
                        </li>
                      ))}
                  </ul>
                  <Button
                    text={`Descargar Certificado`}
                    disabled={false}
                    onClick={() => handleDownloadCertified(step.event)}
                  />
                </div>
              ))}
            {!!crecerDates.length &&
              CrecerNextClasses?.map((step) => (
                <div className="column next-classes" key={step.name}>
                  <h3 className="sticky-header">
                    <span style={{ color: '#191919' }}>Próximas Clases</span>
                    <span className="sub">{step.name}</span>
                  </h3>
                  <p className="attendance-sign">
                    Puedes asistir a las clases que te hagan falta en estas fechas:
                  </p>
                  <ul>
                    {crecerDates
                      ?.filter((date) => {
                        const attendanceDate = new Date(date.attendanceDate);
                        return attendanceDate >= today && date.event === step?.event;
                      })
                      .map((date) => (
                        <li
                          key={date.id}
                          style={{
                            marginBottom: '15px',
                            background: '#FFF',
                            borderRadius: '8px',
                            padding: '5px',
                            border: '1px solid #191919',
                          }}
                        >
                          <div style={{ fontWeight: '500' }}>{date.attendanceName}</div>
                          <div style={{ fontSize: '14px', color: '#767676' }}>
                            {dayInTZ(date.attendanceDate, 'MMMM DD [de] yyyy')}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Seminarios',
      render: () => (
        <Tab.Pane className="custom-pane">
          <div className="columns">
            {!!Seminarios.length &&
              Seminarios.map((step) => (
                <div className="column" style={{ textAlign: 'center' }} key={step.event}>
                  <h3
                    style={{ fontWeight: 500, color: '#ff776a', textAlign: 'left' }}
                    dangerouslySetInnerHTML={{ __html: step.name }}
                  />
                  <ul>
                    {crecerDates
                      ?.filter((d) => d.event === step.event)
                      ?.map((date) => (
                        <li key={date.id} className="step-item">
                          <span>{date.attendanceName}</span>
                          {attendanceStatus(step.stepsMade, date)}
                        </li>
                      ))}
                  </ul>
                  <Button
                    text={`Descargar Certificado`}
                    disabled={false}
                    onClick={() => handleDownloadCertified(step.event)}
                  />
                </div>
              ))}
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <DashboardCrecerComponent>
      <div style={{ display: 'none' }}>
        {selectedCourse && (
          <CertificateGenerator
            ref={pdfRef}
            studentName={`${guest.name} ${guest.lastName}`}
            course={selectedCourse}
          />
        )}
      </div>
      <h2 style={{ fontSize: '30px', color: '#5f5f5f', fontWeight: 400 }}>Tus pasos en</h2>
      <h2 className="title">Crecer & Seminarios</h2>
      <Tab panes={panes} />
      <NotificationModal
        header={notificationHeader}
        message={notificationMessage}
        open={notificationOpen}
        onClose={() => setNotificationOpen(false)}
      />
    </DashboardCrecerComponent>
  );
};

export default Crecer;
