import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import Form from '../../../../components/form/Form';
import { formatCurrency, formInput } from '../../../../components/helpers';
import { Input } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import { PartialPayment } from './event-modals/partialPayment';
import { ReservationExtraInfo, verifyReservationForm } from './components/reservationExtraInfo';

const ReservationStepFillData = (props) => {
  const {
    inputs,
    setInputs,
    setStep,
    defaulteventSlugname,
    firstEventOfQuery,
    sendReservation,
    loading,
    services,
  } = props;
  const [errorInputs, set_errorInputs] = React.useState({});

  const totalDonation = Math.abs(parseInt(firstEventOfQuery.paidEvent)) || 0;

  const nextStep = (e) => {
    e.preventDefault();
    if (verifyReservationForm(firstEventOfQuery, defaulteventSlugname, inputs, set_errorInputs)) {
      window.scrollTo(0, 0);
      return;
    }
    const { eventSlugname } = firstEventOfQuery;
    const { email, name, lastName, identification, volunteerDonationAmount } = inputs;
    if (totalDonation || volunteerDonationAmount > 0) {
      sendReservation({
        goToSummary: false,
        redirect: `/payucheckout?amount=${parseInt(
          totalDonation / (inputs.partialPaid ? 2 : 1) +
            (Math.abs(parseInt(inputs.volunteerDonationAmount)) || 0)
        )}&description=${eventSlugname}&buyerEmail=${email}&buyerFullName=${name} ${lastName}&identification=${identification}`,
      });
      return;
    }
    if (
      Object.values(services || {})?.flat?.(2)?.length === 1 ||
      Object.values(services || {})?.[0]?.[0]?.oneForManyEvents
    ) {
      setInputs(
        { ...inputs, service: firstEventOfQuery, date: firstEventOfQuery.date },
        (newState) => {
          if (newState.service && !newState.reserved) sendReservation({});
        }
      );
      return;
    }
    setStep('selectAService');
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const handleDropDown = ({ name, value }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  const moreInfo = () => {
    return (
      <>
        <ReservationExtraInfo
          inputs={inputs}
          handleInputs={handleInputs}
          selectHandle={selectHandle}
          errorInputs={errorInputs}
          defaulteventSlugname={defaulteventSlugname}
          event={firstEventOfQuery}
        />

        {totalDonation > 0 && (
          <p>
            <strong>Importante:</strong> Si no se concreta la donación al momento de entrar a la
            pagina de pagos, deberás hacer otra inscripción y comenzar otra donación; de lo
            contrario no podremos garantizar tu cupo.
            <br />
            <strong>
              Recuerda que tu cupo solo queda reservado únicamente al momento de completar el pago
              de la donación.
            </strong>
          </p>
        )}
        {firstEventOfQuery['volunteerDonation'] && (
          <div>
            {formInput({
              Input,
              handler: handleInputs,
              label: 'Donación voluntaria',
              name: 'volunteerDonationAmount',
              value: inputs['volunteerDonationAmount'],
              errorInputs,
              type: 'number',
            })}
          </div>
        )}
        <PartialPayment
          event={firstEventOfQuery}
          inputs={inputs}
          setInputs={setInputs}
          totalDonation={totalDonation}
        />
        {totalDonation > 0 && (
          <p style={{ padding: '20px 0 10px 0' }}>
            <strong>{inputs.partialPaid ? 'Primera ' : ''}Donación:</strong>{' '}
            {formatCurrency(
              totalDonation / (inputs.partialPaid ? 2 : 1) +
                (Math.abs(parseInt(inputs.volunteerDonationAmount)) || 0)
            )}
          </p>
        )}
      </>
    );
  };
  return (
    <form onSubmit={(e) => nextStep(e)} className="Registration__form">
      {firstEventOfQuery?.additionalinformation?.confirmDataInfo && (
        <div style={{ margin: '30px 0' }}>
          <ReactMarkdown className="line-break">
            {firstEventOfQuery?.additionalinformation?.confirmDataInfo}
          </ReactMarkdown>
        </div>
      )}
      <h2>{inputs.createdAt ? 'Confirma' : 'Llena'} los datos del asistente</h2>
      {defaulteventSlugname()?.toLowerCase()?.includes('camp') && (
        <p style={{ margin: 0 }}>
          Llena esto con la información del campista, de la persona que asistirá al Camp.
        </p>
      )}
      {Object.values(errorInputs)?.includes(true) && (
        <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
      )}
      {errorInputs?.Not_Complaint === true && (
        <>
          <h4 style={{ color: 'red', margin: 0 }}>
            Lo sentimos no cumples las condiciones para asistir a{' '}
            {firstEventOfQuery?.eventSlugname?.replace(/_/g, ' ')}.
          </h4>
          <p style={{ margin: 0 }}>Si crees que es un error por favor escribenos.</p>
        </>
      )}
      {errorInputs?.Not_Complaint_Relaciones === true && (
        <p style={{ margin: '0 0 10px 0' }}>
          Para poder participar del seminario, debes de tener líder, haber realizado encuentro (paso
          1 crecer) y no haber recibido el seminario más de 1 vez. <br />
          Si tienes dudas, por favor escribenos:{' '}
          <a href={'emailto:relacionessanascomunidadmde@gmail.com'}>
            relacionessanascomunidadmde@gmail.com
          </a>
          .
        </p>
      )}
      <Form
        saveTag={totalDonation ? 'Ir a donar' : 'Continuar '}
        Attendant={inputs}
        userprofile={true}
        handleInputs={handleInputs}
        handleDropDown={handleDropDown}
        errorInputs={errorInputs}
        sendInForm
        showAge
        showGender
        moreInfo={moreInfo}
        showBirthdate
        loadedData={loading}
        submit={(e) => nextStep(e)}
      />
    </form>
  );
};

export default ReservationStepFillData;
