import { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import Perfil from './components/Profile';
import Grupos from './components/Groups';
import Crecer from './components/Crecer';
import Eventos from './components/Events'; // Importa el componente Eventos
import Encuestas from './components/Encuestas'; // Importa el componente Encuestas
import Experiencia from './components/Experiencia'; // Importa el componente Experiencia
import { DashboardComponent } from './components/styles/DashboardStyles';
import { GuestDashboardContext, GuestDashboardProvider } from './components/guestDashboardContext';
import {
  getAVolunteer,
  getCrecerDates,
  getGuest,
  getGuestGroup,
  getGuestGroups,
} from './components/guestDashboardApiCalls';
import GuestLogin from './components/guestLogin';
import queryString from 'query-string';
import { changeDocTitle } from '../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUsers,
  faSeedling,
  faCalendar,
  faPoll,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

const GuestDashboard = () => {
  const iconMapping = {
    perfil: faHome,
    grupos: faUsers,
    crecer: faSeedling,
    eventos: faCalendar,
    encuestas: faPoll,
    experiencia: faStar,
  };

  const {
    guest,
    setGuest,
    loginCredentials,
    setCrecerDates,
    setExperienceVolunteer,
    setLoginCredentials,
    setGuestGroup,
    setGuestGroups,
  } = useContext(GuestDashboardContext);

  let query = window.location.search;
  let parsedQueryString = queryString.parse(query);

  useEffect(() => {
    if (parsedQueryString.identification && parsedQueryString.phone) {
      getInfo(parsedQueryString);
    }
  }, []);

  const getInfo = async (inputs) => {
    await getGuest({ ...(inputs || {}), setGuest });
    setLoginCredentials(inputs);
  };

  useEffect(() => {
    changeDocTitle('Perfil');
    if (loginCredentials?.identification && loginCredentials?.phone) {
      getAVolunteer({ ...loginCredentials, setExperienceVolunteer });
      getGuestGroup({ ...loginCredentials, setGuestGroup });
      getGuestGroups({ ...loginCredentials, setGuestGroups });
    }
    getCrecerDates({ setCrecerDates });
  }, [loginCredentials]);

  return (
    <DashboardComponent>
      <Router>
        <div style={{ display: 'flex', height: '88vh' }}>
          {!!guest?.id ? (
            <>
              <div className="sidebar">
                <div className="containerImg">
                  <img src={guest?.photo} alt="" className="profile-img" />
                  <h4
                    style={{
                      marginTop: '10px',
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: '23px',
                    }}
                  >
                    {guest?.name}
                  </h4>
                  <h4 style={{ marginTop: '2px', fontWeight: 200, fontSize: '16px' }}>
                    {guest?.status}
                  </h4>
                </div>
                <br></br>
                <ul className="menuList">
                  <li>
                    <NavLink exact to="/perfil/info" activeClassName="activePerfil">
                      <span className="icon">
                        <FontAwesomeIcon icon={iconMapping.perfil} />
                      </span>
                      <span className="text">Perfil</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/perfil/crecer" activeClassName="activeCrecer">
                      <span className="icon">
                        <FontAwesomeIcon icon={iconMapping.crecer} />
                      </span>
                      <span className="text">Crecer</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/perfil/grupos" activeClassName="activeGrupos">
                      <span className="icon">
                        <FontAwesomeIcon icon={iconMapping.grupos} />
                      </span>
                      <span className="text">Grupos</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/perfil/experiencia" activeClassName="activeExperiencia">
                      <span className="icon">
                        <FontAwesomeIcon icon={iconMapping.experiencia} />
                      </span>
                      <span className="text">Experiencia</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div style={{ flex: 1, padding: '0px' }}>
                <Switch>
                  <Route path="/perfil/info" component={Perfil} />
                  <Route path="/perfil/grupos" component={Grupos} />
                  <Route path="/perfil/crecer" component={Crecer} />
                  <Route path="/perfil/eventos" component={Eventos} />
                  <Route path="/perfil/encuestas" component={Encuestas} />
                  <Route path="/perfil/experiencia" component={Experiencia} />
                  <Redirect exact from="/perfil" to="/perfil/info" />
                </Switch>
              </div>
            </>
          ) : (
            <GuestLogin getInfo={getInfo} />
          )}
        </div>
      </Router>
    </DashboardComponent>
  );
};

const DashboardWithProvider = () => (
  <GuestDashboardProvider>
    <GuestDashboard />
  </GuestDashboardProvider>
);

export default DashboardWithProvider;
