import React from 'react';
import { Button, Modal, Tab, Segment, Accordion, Checkbox, Select } from 'semantic-ui-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import Swal from 'sweetalert2';
import { chPerm, classDictionary } from '../helpers';
import seminaryForms from '../../services/seminaryService';
import ExpressLogin from '../helpers/expressLoginModal';
import { formatCompletedAttendance } from '../eventsLogin/AttendanceModal';

const SetSeminaryGroupAttendance = (props) => {
  const { group, setOpen, getInfo, activeMembers } = props;
  const [info, set_info] = React.useState({});
  const [createAttendance, set_createAttendance] = React.useState(false);
  const [studentsToMarkAttendance, set_studentsToMarkAttendance] = React.useState([]);
  const [focused, set_focused] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [attendanceDate, set_attendanceDate] = React.useState(null);

  const selectHandle = (a, { value, name }) => {
    set_info({ ...info, [name]: value });
  };

  const seminaryDictionary = {
    'Para_Siempre._': 'ParaSiempre',
    'Transformacion_Empresarial._': 'TransformacionEmpresarial',
  };

  React.useEffect(() => {
    let allMembers = [];
    if (activeMembers) {
      activeMembers?.forEach((member) => {
        if (member.male?.id) {
          allMembers.push(member.male);
        }
        if (member.female?.id) {
          allMembers.push(member.female);
        }
      });
    }
    set_info({ ...info, allMembers });
  }, [activeMembers]);

  React.useEffect(() => {
    set_studentsToMarkAttendance([]);
    set_attendanceDate(null);
  }, [createAttendance]);

  const attendance = info?.allMembers?.map((member) => {
    const seminaryAttendance = member.Seminaries?.find(
      (attendance) => attendance.seminary === seminaryDictionary[group.activityName]
    );
    const allClasses = Object.entries(seminaryAttendance || {})?.filter(([key, value]) =>
      key.includes('class')
    );
    return {
      key: member.id,
      title: `${member.name} ${member.lastName} (${
        allClasses.filter(([key, value]) => value).length
      })`,
      content: {
        content: (
          <p style={{ margin: '0 0 0 30px' }}>
            {allClasses?.length ? (
              <>
                <strong>Fechas:</strong> <br />
                {allClasses?.map(([key, value]) => (
                  <span style={{ display: 'block' }} key={key}>
                    <strong>{classDictionary(key)}: </strong>
                    {value ? formatCompletedAttendance(value, 'DD-MM-YYYY') : 'No registra'}
                  </span>
                ))}
              </>
            ) : (
              'No registra ninguna asistencia'
            )}
          </p>
        ),
      },
    };
  });

  const attendanceRegister = () => {
    let body = {
      attendanceDate: moment(attendanceDate).toISOString(),
      studentsToMarkAttendance,
      classNumber: info.classNumber,
      seminary: seminaryDictionary[group.activityName],
    };

    Swal.fire({
      title: '¿Seguro que desea agregar esta asistencia?',
      text: `Se marcará la asistencia de la ${classDictionary(
        info.classNumber
      )} en la fecha ${moment(attendanceDate).format('DD/MM/YYYY')} a ${
        studentsToMarkAttendance.length
      } personas.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, marcar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        setLoading(true);
        seminaryForms
          .setGroupSeminaryAttendance(body)
          .then((response) => {
            setLoading(false);
            if (response.data.success) {
              Swal.fire('Asistencia actualizada');
              getInfo();
              set_createAttendance(false);
            } else {
              Swal.fire('Hubo un error');
            }
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire('Hubo un error');
          });
      }
    });
  };

  const toggleAttendance = (id) => {
    let studentsToMarkAttendanceArr = studentsToMarkAttendance || [];
    if (studentsToMarkAttendance.includes(id)) {
      studentsToMarkAttendanceArr = studentsToMarkAttendanceArr.filter((a) => a !== id);
    } else {
      studentsToMarkAttendanceArr = [...studentsToMarkAttendanceArr, id];
    }
    set_studentsToMarkAttendance(studentsToMarkAttendanceArr);
  };

  const createAttendanceModal = () => (
    <Modal open onClose={() => set_createAttendance(false)}>
      <Modal.Header>Añadir Asistencia</Modal.Header>
      <Modal.Content>
        <p style={{ color: 'red' }}>
          Selecciona la fecha de la clase, el numero de la clase y las personas para añadirle
          asistencial seminario {group.activityName?.replace(/_/g, ' ')}.
        </p>
        <SingleDatePicker
          date={
            new Date(attendanceDate) === 'Invalid Date' || !attendanceDate
              ? null
              : moment(attendanceDate)
          }
          onDateChange={(newDate) => set_attendanceDate(newDate ? newDate._d : newDate)}
          focused={focused}
          onFocusChange={({ focused }) => set_focused(focused)}
          placeholder="dd/mm/aaaa"
          showDefaultInputIcon={true}
          numberOfMonths={1}
          displayFormat="DD/MM/YYYY"
          isOutsideRange={() => false}
          id="attendanceDate"
        />{' '}
        <div>
          <Select
            placeholder="Clase para marcar"
            style={{
              display: 'inline-block',
              width: '45%',
              margin: ' 7px 0',
            }}
            onChange={selectHandle}
            loading={loading}
            name="classNumber"
            options={[
              'class1',
              'class2',
              'class3',
              'class4',
              'class5',
              'class6',
              'class7',
              'class8',
              'class9',
              'class10',
              'class11',
              'class12',
            ]?.map((a) => ({ key: a, value: a, text: classDictionary(a) }))}
          />
        </div>
        {new Date(attendanceDate) === 'Invalid Date' || !attendanceDate
          ? null
          : moment(attendanceDate).format('DD-MMMM-YYYY')}
        <p>Selecciona las personas que asistieron a la clase</p>
        {info?.allMembers?.map((member) => (
          <Checkbox
            label={`${member.name} ${member.lastName}`}
            name={member.id}
            checked={studentsToMarkAttendance.includes(member.id)}
            style={{ padding: '10px 10px', display: 'block' }}
            onClick={() => toggleAttendance(member.id)}
          />
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => set_createAttendance(false)}>Cerrar</Button>
        <Button
          secondary
          onClick={attendanceRegister}
          loading={loading}
          disabled={!attendanceDate || studentsToMarkAttendance.length === 0 || !info.classNumber}
        >
          Añadir
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open>
      <Modal.Header>Ver y marcar asistencia al seminario</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Tab.Pane>
            <p style={{ margin: 0 }}>Asistencia de tu grupo por persona</p>
            <div style={{ textAlign: 'end' }}>
              {chPerm(['seminary']) ? (
                <Button onClick={() => set_createAttendance(true)}>Marcar Asistencia</Button>
              ) : (
                <>
                  <p style={{ margin: '0' }}>Para marcar asistencia es necesario iniciar sesión:</p>
                  <ExpressLogin
                    email={'asistenciaSeminarios'}
                    onSuccessLogin={() => setOpen(false)}
                  />
                </>
              )}
            </div>
            <Accordion panels={attendance} />
            {activeMembers && activeMembers.length === 0 && (
              <Segment vertical>Aún no tienes personas registradas</Segment>
            )}
          </Tab.Pane>
          {createAttendance && createAttendanceModal()}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SetSeminaryGroupAttendance;
