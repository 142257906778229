import { useRef, forwardRef, useImperativeHandle } from 'react';
import { useReactToPrint } from 'react-to-print';
import './styles/certificateGenerator.css';
import Graphic from './Graphic';

const months = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
];

const courses = {
  RelacionesSanas: {
    courseName: 'Relaciones Sanas',
    text: 'Si vivimos en la luz, así como Dios está en la luz, entonces tenemos comunión unos con otros, y la sangre de Jesús, su Hijo, nos limpia de todo pecado.',
    signature: 'Pastores Cristian Ortiz y Tatiana Mendez',
    color: 'rgba(167, 208, 148, 1)',
    verse: '1 Juan 1:7',
    textColor: 'black',
    top: 525,
  },
  ParaSiempre: {
    courseName: 'Para Siempre',
    text: 'El amor de él no depende del respeto de ella y el respeto de ella no depende del amor de él.',
    signature: 'Pastores Jonathan Morales y Maria Elena Pulgarín',
    color: 'rgba(53, 16, 17, 1)',
    verse: 'Efesios 5:33',
    textColor: 'white',
    top: 525,
  },
  ProyectoMatrimonio: {
    courseName: 'Proyecto Matrimonio',
    text: 'Sin embargo, no comiences sin calcular el costo. Pues, ¿quién comenzaría a construir un ediﬁcio sin primero calcular el costo para ver si hay suﬁciente dinero para terminarlo?',
    signature: 'Pastores Jonathan Morales y Maria Elena Pulgarín',
    color: 'rgba(179, 26, 34, 1)',
    verse: 'Lucas 14:28',
    textColor: 'white',
    top: 500,
  },
  EscueladeHeroes: {
    courseName: 'Escuela de Héroes',
    text: 'Dirige a tus hijos por el camino correcto, y cuando sean mayores, no lo abandonarán.',
    signature: 'Alex Ortíz y Paula Agudelo',
    color: 'rgba(0, 124, 120, 1)',
    verse: 'Proverbios 22:6',
    textColor: 'white',
    top: 540,
  },
  TransformacionEmpresarial: {
    courseName: 'Transformación Empresarial',
    text: 'Así, todos nosotros, que con el rostro descubierto reﬂejamos como en un espejo la gloria del Señor, somos transformados a su semejanza con más y más gloria por la acción del Señor, que es el Espíritu',
    signature: 'Pastores Juan Guillermo y Liliana Ricaurte',
    color: 'rgba(28, 48, 83, 1)',
    verse: '2 Corintios 3:18',
    textColor: 'white',
    top: 540,
  },
  crecerVida: {
    courseName: 'Paso dos VIDA',
    text: 'El propósito del ladrón es robar y matar y destruir; mi propósito es darles una vida plena y abundante.',
    signature: 'Pastores Juan Guillermo y Liliana Ricaurte',
    color: 'rgba(160, 217, 248, 1)',
    verse: 'Juan 10:10',
    textColor: 'black',
    top: 500,
  },
  crecerInfluence: {
    courseName: 'Paso tres INFLUENCIA',
    text: 'Por lo tanto, hablamos a otros de Cristo, advertimos a todos y enseñamos a todos con toda la sabiduría que Dios nos ha dado. Queremos presentarlos a Dios perfectos en su relación con Cristo. Es por eso que trabajo y lucho con tanto empeño, apoyado en el gran poder de Cristo que actúa dentro de mí',
    signature: 'Pastores Juan Guillermo y Liliana Ricaurte',
    color: 'rgba(239, 123, 36, 1)',
    verse: 'Colosenses 1:28-29',
    textColor: 'black',
    top: 450,
  },
};
const getDateForCertificate = (course) => {
  const date = new Date();
  const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
  return `${day} de ${months[month]} de ${year}`;
};

const Certificate = ({ studentName, course }) => (
  <div id="container" className="container certifications">
    <div id="logo" className="logo"></div>
    <div
      id="certificate_date"
      className="certificate_date"
      style={{ backgroundColor: courses[course].color, color: courses[course].textColor }}
    >
      Medellín, {getDateForCertificate(course)}
    </div>
    <Graphic color={courses[course].color} />
    <div className="recongnize">
      <p>La iglesia Comunidad reconoce a:</p>
    </div>
    <div className="studentName">
      <p>{studentName}</p>
    </div>
    <div className="above_line"></div>
    <div className="merit">
      <p>
        Por haber completado con éxito el seminario de formación espiritual{' '}
        <p className="course_name">{courses[course].courseName}</p> y en testimonio de ello le
        otorga el presente diploma.
      </p>
    </div>
    <div>
      <p className="signature">{courses[course].signature}</p>
    </div>
    <div className="below_line"></div>
    <div className="verse_container" style={{ top: courses[course].top }}>
      <p>{courses[course].text}</p>
      <p className="left_text">- {courses[course].verse}</p>
    </div>
  </div>
);

const CertificateGenerator = forwardRef(({ studentName, course }, ref) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Certificado_${studentName}_${course}.pdf`,
  });

  useImperativeHandle(ref, () => ({
    downloadPdf: handlePrint,
  }));

  return (
    <div style={{ display: 'none' }}>
      <div ref={componentRef}>
        <Certificate studentName={studentName} course={course} />
      </div>
    </div>
  );
});

export default CertificateGenerator;
