import React from 'react';
import queryString from 'query-string';
import Barcode from 'react-barcode';
import printQueueService from '../../services/printQueue';
import moment from 'moment';

const PrintPage = (props) => {
  let query = props.location.search;
  let parsed = queryString.parse(query);

  function updateAPrintQueue(identification) {
    if (identification) {
      try {
        printQueueService.updateAPrintQueue(identification);
      } catch (error) {
        console.log(error);
      }
    }
  }
  function isWithinOneWeek(date) {
    const inputDate = moment(date).year(moment().year());
    const currentDate = moment();
    const oneWeekEarlier = currentDate.clone().subtract(1, 'weeks');
    const oneWeekLater = currentDate.clone().add(1, 'weeks');

    return inputDate.isBetween(oneWeekEarlier, oneWeekLater);
  }

  React.useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 500);

    setTimeout(() => {
      updateAPrintQueue(parsed.identification);
      window.close();
    }, 2000);
  }, []);

  const generacionesBirthday = () => {
    const years = moment().diff(moment(parsed.birthdate).subtract(1, 'month'), 'years');
    const isUpgrading79 = years === 7;
    const isUpgrading10 = years === 10;
    return (
      isWithinOneWeek(parsed.birthdate) && (
        <div className="birthdate">
          <p>¡Feliz Cumpleaños!</p>
          <p className="child">{parsed.name}</p>
          <p className="date">{moment(parsed.birthdate).format('DD MMMM YYYY')}</p>
          <p className="new-age">
            🧁 <span> {years} años </span> 🥳
          </p>
          {(isUpgrading79 || isUpgrading10) && (
            <p className="upgrade">
              ¡Felicidades! ya subiste a: {isUpgrading79 && '79'} {isUpgrading10 && '10'}
            </p>
          )}
        </div>
      )
    );
  };

  if (parsed.category.includes('eneraciones'))
    return (
      <section className="print generaciones">
        <div className="children">
          <p className="code">{parsed.code.toUpperCase()}</p>
          <p className="child">
            {parsed.name},{' '}
            <span className="age">{moment().diff(parsed.birthdate, 'years')} años</span>
          </p>
          <p className="generaciones">{parsed.category?.replace(/_/g, ' ')}</p>
          <p className="event_date">{parsed.date}</p>
          <p className="acudiente">
            <span className="name">
              <strong>Acudiente:</strong> {parsed.parentFullname}
            </span>
          </p>
          <p className="acudiente">
            <span className="identification">
              <strong>Cedula:</strong>&nbsp;{parsed.parentIdentification}&nbsp;<strong>Cel:</strong>
              &nbsp;{parsed.phone}
            </span>
          </p>
          {!!parsed.observations && <p className="observations">Ver nota acudiente</p>}
        </div>
        <div className="parent">
          <p className="code">{parsed.code.toUpperCase()}</p>
          <p className="child">{parsed.name}</p>
          <p className="acudiente">
            <span className="name">
              <strong>Nombre acudiente:</strong> {parsed.parentFullname}
            </span>
          </p>
          <p className="event_date">{parsed.date}</p>
        </div>
        {generacionesBirthday()}
      </section>
    );
  else
    return (
      <section className="print">
        <p>{parsed.name}</p>
        <div className="barcode">
          <Barcode value={parsed.identification} />
        </div>
      </section>
    );
};

export default PrintPage;
