import React, { useEffect } from 'react';
import { Accordion, Button, Icon, Modal } from 'semantic-ui-react';
import MyButton from '../common/Button';
import moment from '../../services/moment_instance';
import { chPerm, dayInTZ } from '../helpers';
import { SingleDatePicker } from 'react-dates';

export const formatCompletedAttendance = (date, format = 'DD-MM-YYYY, h:mm:ss a') => {
  return date !== '2020-01-01T00:00:00.000Z'
    ? `Asistencia: ${dayInTZ(date, format)}`
    : '¡Completado!';
};

const AttendanceModal = (props) => {
  const {
    title,
    loading,
    event,
    canCreate,
    content,
    updateAttendance,
    set_openModal,
    resetForm,
    loadedData,
    openModal,
    set_changedDate,
    changedDate,
    kind,
    eventDates,
    createAttendance,
    comments,
  } = props;
  const [activeIndex, set_activeIndex] = React.useState(null);
  const [focused, set_focused] = React.useState(false);
  const [activeChangeDate, set_activeChangeDate] = React.useState(false);

  const handleClick = (index) => {
    const newIndex = activeIndex === index ? -1 : index;
    set_activeIndex(newIndex);
  };

  useEffect(() => {
    if (!content?.[0]?.percentage) {
      set_activeIndex(0);
    }
  }, [content?.[0]?.percentage]);

  const authToChangeDate =
    (chPerm(['seminaryAdmin']) && kind === 'seminary') ||
    (chPerm(['crecerAdmin']) && kind === 'crecer');

  const checkAndRegisterAttendance = (content, date) => {
    const showDate = () => {
      if (content?.[0]?.[date.attendanceCode]) {
        return formatCompletedAttendance(content?.[0]?.[date.attendanceCode]);
      } else {
        return 'Sin completar.';
      }
    };

    return (
      <>
        {showDate()}
        {chPerm(['crecer', 'seminary']) &&
          ((dayInTZ(date.attendanceDate) === dayInTZ(new Date()) &&
            dayInTZ(content?.[0]?.[date.attendanceCode]) !== dayInTZ(new Date())) ||
            (activeChangeDate &&
              authToChangeDate &&
              dayInTZ(content?.[0]?.[date.attendanceCode]) !== dayInTZ(changedDate))) && (
            <Button
              compact
              primary
              loading={loading}
              onClick={() =>
                updateAttendance({
                  classNumber: date.attendanceCode,
                  event: event,
                  today: new Date(),
                })
              }
            >
              {`${content?.[0]?.[date.attendanceCode] ? 'Actualizar' : 'Marcar'} asistencia de ${
                date.attendanceName
              }`}
            </Button>
          )}
      </>
    );
  };

  const changeDate = () => {
    if (authToChangeDate)
      return (
        <>
          {!activeChangeDate && (
            <Button size="tiny" onClick={() => set_activeChangeDate(!activeChangeDate)}>
              Cambiar fecha
            </Button>
          )}
          {activeChangeDate && (
            <div style={{ margin: '0 0 20px 0' }}>
              <label htmlFor={'date'} style={{ display: 'block' }}>
                Fecha de asistencia
              </label>
              <SingleDatePicker
                date={
                  new Date(changedDate) === 'Invalid Date' || !changedDate
                    ? null
                    : moment(changedDate)
                }
                onDateChange={(newDate) => set_changedDate(newDate ? newDate._d : newDate)}
                focused={focused}
                onFocusChange={({ focused }) => set_focused(focused)}
                placeholder="dd/mm/aaaa"
                showDefaultInputIcon={true}
                numberOfMonths={1}
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                id="date"
              />
            </div>
          )}
        </>
      );
    return null;
  };

  const attendanceAtChurch = () => {
    return (
      <>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={() => handleClick(0)}>
          <Icon name="dropdown" />
          Presencial
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          {changeDate()}
          {loadedData && <p style={{ color: 'red' }}>{loadedData}</p>}
          {content && content.length === 0 && <p>No se encuentra registro de {title}.</p>}
          {content && content.length === 0 && canCreate && (
            <Button loading={loading} primary onClick={() => createAttendance({ event: event })}>
              {'Iniciar asistencia de ' + title}
            </Button>
          )}
          {content?.length !== 0 &&
            eventDates &&
            eventDates
              ?.filter((d) => d.event === event)
              ?.map((date) => (
                <p>
                  <strong>{date.attendanceName}:</strong>{' '}
                  {checkAndRegisterAttendance(content, date)}
                </p>
              ))}
        </Accordion.Content>
      </>
    );
  };

  const virtualAttendance = () => {
    if (content?.[0] && content?.[0]?.percentage) {
      return (
        <>
          <Accordion.Title active={activeIndex === 1} index={1} onClick={() => handleClick(1)}>
            <Icon name="dropdown" />
            Online
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <p>
              <strong>Porcentaje completado:</strong> {content[0].percentage}%
              <br />
              <small>
                <i>Este valor se actualiza de manera periodica.</i>
              </small>
            </p>
          </Accordion.Content>
        </>
      );
    }
  };

  return (
    <Modal
      trigger={<MyButton text={title} onClick={() => set_openModal(event)} />}
      open={openModal === event}
      onClose={() => set_openModal('')}
      basic
      size="small"
      style={{ backgroundColor: 'white' }}
    >
      <Modal.Header style={{ color: 'black' }}>{title}</Modal.Header>
      <Modal.Content scrolling>
        {comments &&
          comments?.map((comment) => (
            <span
              style={{
                padding: ' 0 0 7px 0',
                color: 'black',
                display: 'block',
              }}
            >
              <i>{comment}</i>
            </span>
          ))}
        <Modal.Description style={{ color: 'black' }}>
          <Accordion styled>
            {virtualAttendance()}
            {attendanceAtChurch()}
          </Accordion>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secundary onClick={() => set_openModal('')}>
          Volver
        </Button>
        {canCreate && (
          <Button
            primary
            onClick={() => {
              resetForm();
              set_openModal('');
            }}
          >
            Ingresar otro asistente
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default AttendanceModal;
